import { message } from "antd";
import axiosInstance from "../../confiq/axiosInstance";
import config from "../../confiq/confiq";
import { adjustDate } from "../../utils";

const baseURL = config.apiUrl;
const headers = config.headers;

export const getPartnerships = async (page, pageSize, search, userRole) => {
    try {
        const endpoint = userRole === 'partner'
            ? `/api/vendor/partnership`
            : `/api/admin/partnership`;

        const response = await axiosInstance.get(`${endpoint}?page=${page || 1}&pageSize=${pageSize || 100}&search=${search || null}`, {
            headers: headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching partnerships:", error);
        throw error;
    }
};

export const getRegisteredPartner = async (id, search) => {
    const response = await axiosInstance.get(
        `api/admin/registeredPartner?page=1&pageSize=100&search=${search}&vendor_id=${id}`,
        {
            headers
        }
    );
    return response?.data;
};

export const postLastActivity = async (id) => {
    const response = await axiosInstance.post(
        `api/vendor/lastActivity`, {
        "vendor_id": id
    },
        {
            headers
        }
    );
    return response?.data;
};



export const fetchLeadQualOptions = async (id) => {
    const response = await axiosInstance.get(
        `${baseURL}api/hubspot/leadQualifiedFilter`,
        {
            params: { vendor_id: id },
            headers
        }
    );
    return response?.data;
};


export const fetchOriginOptions = async (id) => {
    const response = await axiosInstance.get(
        `${baseURL}api/hubspot/originFilter`,
        {
            params: { vendor_id: id },
            headers
        }
    );
    return response?.data;
};

export const fetchSyncDetails = async (vendorId, userRole, localStorageLabel) => {
    try {
        const response = await axiosInstance.get(
            userRole === "Admin" ? `api/hubspot/admin/sync/details?vendor_id=${vendorId}&company=${localStorageLabel}` :
                `api/hubspot/sync/details?vendor_id=${vendorId}`,
            {
                headers: config.headers,
            }
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const syncHubspotData = async (id) => {
    try {
        const response = await axiosInstance.get(`/api/hubspot/syncHubspot?vendor_id=${id}`, {
            headers: config.headers,
        });

        const { totalContactUpdated, totalDealsUpdated } = response?.data?.data;

        if (totalContactUpdated > 0) {
            message.success(`${totalContactUpdated} contact${totalContactUpdated > 1 ? 's' : ''} updated!`);
        }

        if (totalDealsUpdated > 0) {
            message.success(`${totalDealsUpdated} deal${totalDealsUpdated > 1 ? 's' : ''} updated!`);
        }

        if (totalContactUpdated > 0 || totalDealsUpdated > 0) {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }

        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchLeads = async (id, selectedDateRange, searchQuery, userRole, localStorageLabel) => {
    const startDate = adjustDate(selectedDateRange[0], 0, 0, 0, 0);
    const endDate = adjustDate(selectedDateRange[1], 23, 59, 59, 999);

    const formattedStartDate = startDate ? new Date(startDate).toISOString() : null;
    const formattedEndDate = endDate ? new Date(endDate).toISOString() : null;

    try {
        const response = await axiosInstance.get(
            userRole === "Admin" ? `api/lead/adminGetRegistered?vendor_id=${id}&page=1&pageSize=100&search=${searchQuery}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&company=${localStorageLabel}` :
                `api/lead/getRegistered?vendor_id=${id}&page=1&pageSize=100&search=${searchQuery}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
            {
                headers: config.headers,
            }
        );

        return response?.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const registerLead = async (id, values, setIsSubmitting, form, setDrawerVisible) => {
    const payload = {
        vendor_id: id,
        full_name: values.fullName,
        potential_plan: values.potentialPlan,
        email: values.email,
        company_name: values.companyName,
        employee_count: values.employeeCount,
        industry: values.industry
    };

    setIsSubmitting(true);
    try {
        const response = await axiosInstance.post(`/api/lead/register`, payload);
        if (response?.status === 201) {
            message.success("Lead registered successfully!");
            form.resetFields();
            setDrawerVisible(false);
        } else {
            message.error("Failed to register the lead. Please try again.");
        }
    } catch (error) {
        console.error('Error registering the lead:', error);
        message.error("Failed to register the lead. Please try again.");
    } finally {
        setIsSubmitting(false);
    }
};

export const getUserDetails = async (email, vendorId, localStorageLabel, userRole) => {
    try {
        const url = userRole === "Admin"
            ? `/api/hubspot/adminGetUserDetails?email=${email}&vendor_id=${vendorId}&company=${localStorageLabel}`
            : `/api/hubspot/getUserDetails?email=${email}&vendor_id=${vendorId}`;

        // Make the API call
        const response = await axiosInstance.get(url, {
            headers: config.headers,
        });

        return response?.data?.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        message.error("Failed to retrieve user details. Please try again.");
        throw error; // Rethrow the error to handle it later if needed
    }
};


export const updateDealStage = async (dealStageData) => {
    try {
        const response = await axiosInstance.post(`/api/hubspot/deal/updateStage`, dealStageData, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error updating deal stage:", error);
        throw error;
    }
};

export const fetchLeadQualified = async (id) => {
    try {
        const response = await axiosInstance.get(`api/hubspot/leadQualifiedFilter?vendor_id=${id}`, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching lead qualified:", error);
    }
};


export const fetchNotes = async (id, contactId) => {
    try {
        const response = await getAllNotes(id, contactId);
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching notes:", error);
    }
};

export const fetchSqlStages = async (id) => {
    try {
        const response = await axiosInstance.get(`api/hubspot/leadSqlStages?vendor_id=${id}`, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching SQL stages:", error);
    }
};

export const fetchDealStages = async (id) => {
    try {
        const response = await axiosInstance.get(`api/hubspot/deal/stages?vendor_id=${id}`, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching deal stages:", error);
    }
};


export const createNote = async (noteData) => {
    try {
        const response = await axiosInstance.post(`/api/hubspot/createNote`, noteData, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error creating note:", error);
        throw error;
    }
};

export const getAllNotes = async (vendorId, contactId) => {
    try {
        const response = await axiosInstance.get(`/api/hubspot/getAllCreatedNote?vendor_id=${vendorId}&contactId=${contactId}`, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error fetching all notes:", error);
        throw error;
    }
};


export const editNote = async (noteData) => {
    try {
        const response = await axiosInstance.post(`/api/hubspot/edit/note`, noteData, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error editing note:", error);
        throw error;
    }
};

export const deleteNote = async (noteId) => {
    try {
        const response = await axiosInstance.post(`/api/hubspot/delete/note`, { noteId }, {
            headers: config.headers,
        });
        return response?.data?.data;
    } catch (error) {
        console.error("Error deleting note:", error);
        throw error;
    }
};


export const fetchDeals = async (id, selectedOrigin, selectedDateRange, searchQuery, userRole, localStorageLabel) => {
    const startDate = adjustDate(selectedDateRange[0], 0, 0, 0, 0);
    const endDate = adjustDate(selectedDateRange[1], 23, 59, 59, 999);

    const formattedStartDate = startDate ? new Date(startDate).toISOString() : null;
    const formattedEndDate = endDate ? new Date(endDate).toISOString() : null;

    try {
        const response = await axiosInstance.get(
            userRole === "Admin" ? `${config.apiUrl}api/hubspot/adminGetDeals?vendor_id=${id}&origin=${selectedOrigin}&leadQualification=null&startDate=${formattedStartDate}&endDate=${formattedEndDate}&search=${searchQuery}&company=${localStorageLabel}` :
                `${config.apiUrl}api/hubspot/getDeals?vendor_id=${id}&origin=${selectedOrigin}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&search=${searchQuery}`,
            {
                headers: config.headers,
            }
        );
        const data = response?.data?.data;
        const totalCount = response?.data?.totalCount;

        return { data, totalCount };
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const fetchContacts = async (
    id,
    userRole,
    selectedOrigin,
    selectedLeadQual,
    selectedDateRange,
    searchQuery,
    localStorageLabel
) => {
    try {
        const startDate = adjustDate(selectedDateRange[0], 0, 0, 0, 0);
        const endDate = adjustDate(selectedDateRange[1], 23, 59, 59, 999);

        const formattedStartDate = startDate ? new Date(startDate).toISOString() : null;
        const formattedEndDate = endDate ? new Date(endDate).toISOString() : null;

        const apiUrl = userRole === "Admin"
            ? `${config.apiUrl}api/hubspot/adminGetContacts?vendor_id=${id}&origin=${selectedOrigin}&leadQualification=${selectedLeadQual}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&search=${searchQuery}&company=${localStorageLabel}`
            : `${config.apiUrl}api/hubspot/getContacts?vendor_id=${id}&origin=${selectedOrigin}&leadQualification=${selectedLeadQual}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&search=${searchQuery}`;

        const response = await axiosInstance.get(apiUrl, {
            headers: config.headers,
        });

        return response?.data;
    } catch (error) {
        console.error("Error fetching contacts:", error);
        throw error;
    }
};