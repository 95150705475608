import {
    Button,
    Input,
    Form,
    message,
    Tag
} from 'antd';
import {
    ClipboardListOutline,
    LockClosedOutline,
} from 'heroicons-react';
import React, {
    useState
} from 'react';
import moment from 'moment';
import config from '../../../confiq/confiq';
import ConfirmationModal from '../../../components/Modal/confirmationDelete';
import { useParams } from 'react-router-dom';
import { useUserRole } from '../../../context/userContext';
import axiosInstance from '../../../confiq/axiosInstance';

const { TextArea } = Input;

function NotesRequest({
    setLoading,
    setTextAreaHeightOverall,
    form,
    textAreaValue,
    setTextAreaValue,
    textAreaHeight,
    setTextAreaHeight,
    bottomNotesVisible,
    setBottomNotesVisible,
    handleCancel,
    editingNoteId,
    setEditingNoteId,
    handleTextAreaFocus,
    contactId,
    fetchNote
}) {

    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
    const [noteToDelete, ] = useState(null);
    const { id } = useParams();
    const userRole = useUserRole();


    const onFinish = (values) => {
        const payload = {
            "vendor_id": parseInt(id),
            "contactId": contactId,
            "body": values?.notes,
        };

        axiosInstance.post(`${config.apiUrl}api/hubspot/createNote`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                handleCancel();
                message.success('Note created successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            }
        }).catch((error) => {
            console.error("Error creating note:", error);
            setLoading(false);
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                if (editingNoteId) {
                    handleUpdateNote();
                } else {
                    onFinish(values);
                }
            })
            .catch(() => {
            });
    };

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };

    const handleUpdateNote = () => {
        const payload = {
            "vendor_id": parseInt(id),
            "noteId": editingNoteId,
            "body": textAreaValue,
        };

        axiosInstance.post(`${config.apiUrl}api/hubspot/edit/note`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                handleCancel();
                message.success('Note updated successfully');
            }
        }).catch((error) => {
            console.error("Error updating note:", error);
            setLoading(false);
        });
    };

    const handleDeleteNote = (noteId) => {
        const payload = {
            "vendor_id": parseInt(id),
            "noteId": noteId,
        };

        axiosInstance.post(`${config.apiUrl}api/hubspot/delete/note`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                message.success('Note deleted successfully');
                window.location.reload();
            }
        }).catch((error) => {
            console.error("Error deleting note:", error);
            setLoading(false);
        });
    };

    const isSaveDisabled = !textAreaValue.trim();

    return (
        <>
            <div className='flex justify-between gap-2 items-center cursor-pointer bg-white'
            >
                <div>
                    <h4 className="text-lg font-semibold mb-4">
                        Notes {fetchNote && fetchNote.length > 0 && `(${fetchNote.length})`}
                    </h4>
                </div>
            </div>
            <div className=' w-full' >
                {userRole === "Partner" &&
                    <div className='notes-field'>
                        <Form
                            form={form}
                            name="notesForm"
                            layout="vertical"
                            onFinish={editingNoteId ? handleUpdateNote : onFinish}
                        >
                            <Form.Item name="notes" className='mb-0'
                                style={{
                                    height: textAreaHeight,
                                }}
                            >
                                <TextArea
                                    value={textAreaValue}
                                    onChange={handleTextAreaChange}
                                    placeholder={'Add notes here'}
                                    style={{
                                        height: textAreaHeight,
                                        resize: 'none',
                                    }}
                                    onFocus={handleTextAreaFocus}
                                    onClick={() => setTextAreaHeightOverall(250)}
                                />
                            </Form.Item>
                            {bottomNotesVisible &&
                                <div className='bottom-notes flex justify-between items-center'>
                                    <div className='grey'>Visible to everyone</div>
                                    <div className='flex gap-4 items-center'>
                                        <span
                                            className='text-[#333333] font-bold cursor-pointer'
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </span>
                                        <Button type="primary" onClick={handleSubmit} htmlType="submit" disabled={isSaveDisabled}>
                                            {editingNoteId ? "Update" : "Save"}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </Form>
                    </div>
                }
                {fetchNote && fetchNote?.map((note) => (
                    <div className={`mt-4 note-history relative ${userRole === "Partner" ? '' : ''}`} key={note.id}>
                        <div className='flex flex-row gap-2'>
                            <div className='Grayish'>
                                <ClipboardListOutline width={20} />
                            </div>
                            <div>
                                <div
                                    dangerouslySetInnerHTML={{ __html: note.body }}
                                    className="container-class markdown mb-2"
                                />
                                <div className='flex flex-row gap-2 mb-2 items-center'>
                                    {note?.privacy === true &&
                                        <div className='Grayish'>
                                            <LockClosedOutline width={14} />
                                        </div>
                                    }
                                    <div className='flex flex-row gap-2 items-center'>
                                        {note?.partner ? (
                                            <Tag color='blue'>Partner</Tag>
                                        ) : (
                                            <Tag className='admin-tag'>Vendor</Tag>
                                        )}
                                    </div>
                                    <div>{moment(note?.lastModifiedDate).fromNow()}</div>
                                </div>
                            </div>
                        </div>
                        {/* {note?.partner && userRole === "Partner" &&
                            <>
                                <div className='flex items-center justify-between gap-3 parent-icon absolute top-0 bottom-4 right-0'>
                                    <div className='edit-delteicon flex items-center justify-center'
                                        onClick={() => {
                                            handleEditNote(note?.id, note?.body);
                                        }}>
                                        <Pencil className='edit-icon' />
                                    </div>
                                    <div className='edit-delteicon flex items-center justify-center'
                                        onClick={() => {
                                            setNoteToDelete(note?.id);
                                            setIsOpenConfirmationModal(true);
                                        }}>
                                        <Trash className='delete-icon' />
                                    </div>
                                </div>
                            </>
                        } */}
                    </div>
                ))}
                {isOpenConfirmationModal && (
                    <ConfirmationModal
                        handleOk={() => {
                            handleDeleteNote(noteToDelete);
                            setIsOpenConfirmationModal(false);
                        }}
                        handleCancel={() => setIsOpenConfirmationModal(false)}
                        selectedProjectId={noteToDelete}
                        open={isOpenConfirmationModal}
                        modalDesc="Are you sure you want to delete this note?"
                    />
                )}
            </div>
        </>
    );
}

export default NotesRequest;