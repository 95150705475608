import { ChevronLeftOutline } from 'heroicons-react';
import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import TableContact from './table';
import { getSyncDetails } from '../vendorDetail';
import { syncHubspotData } from '../apiService';
import { LastdateText } from '..';
import { useUserRole } from '../../../context/userContext';

function ViewContact() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [, setLoading] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false);
    const [syncText, setSyncText] = useState('Sync');
    const [syncDetails, setSyncDetails] = useState(null);
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');

    useEffect(() => {
        getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
    }, [id, localStorageLabel, userRole]);

    const handleSyncClick = async () => {
        setLoadingSync(true);
        setSyncText('Syncing...');
        try {
            await syncHubspotData(id);
            await getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
            setSyncText('Sync');
        } catch (error) {
            setSyncText('Sync');
        } finally {
            setLoadingSync(false);
        }
    };

    return (
        <>

            <div className='flex items-center justify-between gap-2 mb-5'>
                <div className='flex flex-row gap-2 items-center'>
                    <div onClick={() => navigate(-1)} className='cursor-pointer'>
                        <ChevronLeftOutline />
                    </div>
                    {syncDetails?.vendor && <div>
                        <div className='flex flex-row gap-2 items-center'>
                            <div className='text-base Grayish'>{syncDetails?.vendor} &nbsp;•</div>
                            {userRole === "Admin" && <div className='text-base Grayish'>{localStorageLabel} &nbsp;•</div>}
                            <div className='text-black font-medium text-base'>Contacts</div>
                        </div>
                    </div>
                    }
                </div>
                <div className='flex flex-row gap-3 purple-saas22'>
                    <div className='text-sm Grayish'>
                        <LastdateText syncDetails={syncDetails} />
                    </div>
                    {userRole === "Partner" &&
                        <div>
                            <div className='flex flex-row gap-3 items-center'>
                                <div
                                    className='text-sm purple-saas22 font-semibold cursor-pointer flex gap-1 items-center'
                                    onClick={handleSyncClick}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className={`size-4 ${loadingSync ? 'animate-spin' : ''}`}
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                    </svg>
                                    {syncText}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <TableContact />
        </>
    );
}

export default ViewContact;