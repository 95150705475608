import { Drawer, Menu } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    AnnotationOutline,
    DocumentTextOutline,
    HomeOutline,
    NewspaperOutline,
    UserOutline,
    UsersOutline,
} from 'heroicons-react';
import { DealsIcon, EventIcon, pendingApproval } from '../../assets/img';
import { useAuth } from '../../context';
import { useUserRole } from '../../context/userContext';

function getItem(key, text, icon, link, children) {
    return {
        key,
        text,
        icon,
        link,
        children,
    };
}

function MobileMenu({ setOpen, open }) {
    const { userToken } = useAuth();
    const userRole = useUserRole();
    const location = useLocation();

    const buyerMenuItems = [
        ...(userRole && userToken
            ? [
                getItem('/home', 'Home', <HomeOutline />),
                getItem('/projects', 'Requests', <AnnotationOutline />),
            ]
            : []),
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
            children: [
                getItem('/resources?tab=1', 'Web Feed'),
                getItem('/resources?tab=2', 'Stories'),
                // getItem('/resources?tab=3', 'Guides'),
            ],
        },
        getItem('/events', 'Events', <img src={EventIcon} alt='events' />),
        ...(userRole && userToken
            ? [
                // getItem('/jobs', 'Jobs', <BriefcaseOutline />),
                getItem('/discount', 'Discounts', <img src={DealsIcon} alt='events' />),
                getItem('/profile', 'Profile', <UserOutline />),
            ]
            : []),
    ];

    const partnerMenuItems = [
        ...(userRole && userToken
            ? [
                getItem('/home', 'Home', <HomeOutline />),
                // getItem('/request', 'Requests', <AnnotationOutline />),
            ]
            : []),
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
            children: [
                getItem('/resources?tab=1', 'Web Feed'),
                getItem('/resources?tab=2', 'Stories'),
                // getItem('/resources?tab=3', 'Guides'),
            ],
        },
        getItem('/events', 'Events', <img src={EventIcon} alt='events' />),
        ...(userRole && userToken
            ? [
                getItem('/discount', 'Discounts', <img src={DealsIcon} alt='events' />),
                getItem('/profile', 'Profile', <UserOutline />),
            ]
            : []),
    ];

    const adminMenuItems = [
        ...(userRole && userToken
            ? [
                getItem('/home', 'Home', <HomeOutline />),
            ]
            : []),
        // getItem('/projects', 'Projects', <AnnotationOutline />),
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
            children: [
                getItem('/resources?tab=1', 'Web Feed'),
                getItem('/resources?tab=2', 'Stories'),
                // getItem('/resources?tab=3', 'Guides'),
            ],
        },
        getItem('/events', 'Events', <img src={EventIcon} alt='events' />),
        ...(userRole && userToken
            ? [
                // getItem('/jobs', 'Jobs', <BriefcaseOutline />),
                getItem('/discount', 'Discounts', <img src={DealsIcon} alt='events' />),
                getItem('/users', 'Users', <UsersOutline />),
                getItem('/content', 'Content', <DocumentTextOutline />),
                getItem('/inquiries', 'Inquiries', <img src={pendingApproval} alt='pending approval' />, 'https://saas22-help.freshdesk.com/support/login',),
            ]
            : []),
    ];

    const menuItems = userRole === 'Buyer'
        ? buyerMenuItems
        : userRole === 'Partner'
            ? partnerMenuItems
            : adminMenuItems;

    const isMenuItemActive = (menuItem) => {
        return location?.pathname?.startsWith(menuItem?.key);
    };

    return (
        <Drawer
            title={false}
            placement={"top"}
            closable={false}
            style={{ maxHeight: "70vh" }}
            onClose={() => setOpen(false)}
            open={open}
            className='mobile-view-header mobile-sidebar'
        >
            <Menu theme="light" mode="inline" selectedKeys={menuItems?.filter(isMenuItemActive).map(item => item?.key)}>
                {menuItems?.map(item => (
                    <React.Fragment key={item?.key} >
                        <div className='className="mb-4"'>
                            {item?.children ? (
                                <Menu.SubMenu key={item?.key} title={item?.text} icon={item?.icon} className='sub-menu-mobile'>
                                    {item?.children?.map(child => (
                                        <Menu.Item key={child?.key}>
                                            <Link to={child?.key}>{child?.text}</Link>
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            ) : item?.link ? (
                                <Menu.Item key={item?.key} className="custom-menu-item mb-2">
                                    <a href={item?.link} target={'_blank'} rel="noopener noreferrer">
                                        <div className="flex items-center ">
                                            <div className="mr-2">{item?.icon}</div>
                                            <div>{item?.text}</div>
                                        </div>
                                    </a>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key={item?.key} className="custom-menu-item mb-2">
                                    <Link to={item?.key}>
                                        <div className="flex items-center ">
                                            <div className="mr-2">{item?.icon}</div>
                                            <div>{item?.text}</div>
                                        </div>
                                    </Link>
                                </Menu.Item>
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </Menu>
        </Drawer>
    );
}

export default MobileMenu;
