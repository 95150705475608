import { Col, Dropdown, Row, Space, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  BookmarkOutline,
  CalendarOutline,
  DotsVerticalOutline,
  LinkOutline,
  LocationMarkerOutline,
  PencilAltOutline,
  TrashOutline,
} from "heroicons-react";
import axios from "axios";
import config from "../../confiq/confiq";
import { TwitterXBlack, saved2 } from "../../assets/img";
import { useUserRole } from "../../context/userContext";
import CopyToClipboard from "react-copy-to-clipboard";
import ConfirmationModal from "../Modal/confirmationDelete";
import {
  ArrowUpRight,
  Envelope,
  FacebookLogo,
  LinkedinLogo,
  Share,
  SlackLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import { useAuth } from "../../context";
import SignInPopup from "../Modal/signIn";

export const Event = (props) => {
  const { fetchData, setLoading } = props;
  const userRole = useUserRole();
  const { userToken } = useAuth();
  const [strapiUrl, setStrapiUrl] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [event, setEvent] = useState([]);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const borderColors = ["#F7CF66", "#EA5A54", "#5D5FF3", "#55AF68"];

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  useEffect(() => {
    setEvent(props.product);
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, [props.product]);

  const updateBookmarkStatus = (storyId, isBookmarked) => {
    setEvent((prevproject) =>
      prevproject?.map((story) =>
        story?.id === storyId ? { ...story, isBookmarked } : story
      )
    );
  };

  const handleCancel = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleOk = async (selectedProjectId) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/events/remove/${selectedProjectId}`,
        {},
        {
          headers: config.headers,
        }
      );

      if (response?.data?.statusCode === 201) {
        fetchData();
        setLoading(true);
      } else {
        message.error(
          "Oops! Something went wrong. event could not be deleted. Please try again."
        );
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {event?.map((event, index) => {
          const eventId = event?.id;
          const borderColor = borderColors[index % borderColors.length];
          const publishedAt = event?.attributes?.time;
          const eventDate = new Date(publishedAt);

          const handleEditClick = (event) => {
            window.open(
              `${strapiUrl}admin/content-manager/collectionType/api::event.event/${eventId}`
            );
          };

          const handleDeleteClick = async () => {
            setIsOpenConfirmationModal(true);
            setSelectedProjectId(event?.id);
          };

          const postBookmark = async () => {
            if (!userRole || !userToken) {
              setShowSignInPopup(true);
            } else {
              const payload = {
                bookmark_id: event?.id,
                bookmark_type: "event",
              };
              try {
                const response = await axios.post(
                  `${config.apiUrl}api/bookmark/save`,
                  payload,
                  {
                    headers: config.headers,
                  }
                );
                if (response?.data?.statusCode === 201) {
                  updateBookmarkStatus(eventId, true);
                } else {
                  message.error(
                    "Sorry, we couldn't add this to your saved items. Please try again later."
                  );
                }
              } catch (error) {
                console.error("Error validating token:", error);
              }
            }
          };

          const removeBookmark = async () => {
            if (!userRole || !userToken) {
              setShowSignInPopup(true);
            } else {
              const payload = {
                bookmark_id: event?.id,
                bookmark_type: "event",
              };
              try {
                const response = await axios.post(
                  `${config.apiUrl}api/bookmark/remove`,
                  payload,
                  {
                    headers: config.headers,
                  }
                );
                if (response?.data?.statusCode === 201) {
                  updateBookmarkStatus(eventId, false);
                } else {
                  message.error(
                    "Sorry, we couldn't remove this event from your favorites. Please try again later."
                  );
                }
              } catch (error) {
                console.error("Error validating token:", error);
              }
            }
          };

          const handleCardClick = () => {
            if (userRole !== "Admin") {
              const payload = {
                metric_id: event?.id,
                metric_type: "event",
                view_count: 1,
                recent_views: 1,
              };

              axios
                .post(`${config.apiUrl}api/metrics/save`, payload, {
                  headers: config.headers,
                })
                .catch((error) => {
                  console.error("Error validating token:", error);
                });
            }
          };

          const success = () => {
            messageApi.open({
              type: "success",
              content: "Copied successfully",
            });
          };

          const items = [
            {
              key: "1",
              label: (
                <CopyToClipboard text={event?.attributes?.redirection_URL}>
                  <div
                    className="flex flex-row gap-2 items-center"
                    onClick={success}
                  >
                    <LinkOutline size={16} /> Copy Link
                  </div>
                </CopyToClipboard>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => handleMenuItemClick(items[1])}
                >
                  <FacebookLogo size={17} /> Share on Facebook
                </div>
              ),
            },

            {
              key: "3",
              label: (
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => handleMenuItemClick(items[2])}
                >
                  <img src={TwitterXBlack} alt="twitter black" /> Share on X
                </div>
              ),
            },

            {
              key: "4",
              label: (
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => handleMenuItemClick(items[3])}
                >
                  <LinkedinLogo size={17} /> Share on LinkedIn
                </div>
              ),
            },
            {
              key: "5",
              label: (
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => handleMenuItemClick(items[4])}
                >
                  <WhatsappLogo size={17} /> Share on Whatsapp
                </div>
              ),
            },
            {
              key: "6",
              label: (
                <CopyToClipboard text={event?.attributes?.redirection_URL}>
                  <div
                    className="flex flex-row gap-2 items-center"
                    onClick={() => handleMenuItemClick(items[5])}
                  >
                    <SlackLogo size={17} /> Share on Slack
                  </div>
                </CopyToClipboard>
              ),
            },
            {
              key: "7",
              label: (
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => handleMenuItemClick(items[6])}
                >
                  <Envelope size={17} /> Share via Mail
                </div>
              ),
            },
          ];

          const handleMenuItemClick = async (item) => {
            switch (item.key) {
              case "1":
                break;
              case "2":
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    event?.attributes?.redirection_URL
                  )}`,
                  "_blank"
                );
                break;
              case "3":
                window.open(
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    event?.attributes?.redirection_URL
                  )}&text=Your%20tweet%20text`,
                  "_blank"
                );
                break;
              case "4":
                window.open(
                  `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                    event?.attributes?.redirection_URL
                  )}`,
                  "_blank"
                );
                break;
              case "5":
                window.open(
                  `https://api.whatsapp.com/send?text=${encodeURIComponent(
                    ""
                  )}%20${encodeURIComponent(event?.attributes?.redirection_URL)}`,
                  "_blank"
                );
                break;
              case "6":
                window.open(
                  `slack://open?team=your_slack_team&channel=your_slack_channel&text=${encodeURIComponent(
                    ""
                  )}`,
                  "_blank"
                );
                break;
              case "7":
                window.open(
                  `mailto:?subject=Your%20subject&body=${encodeURIComponent(
                    `${event?.attributes?.redirection_URL}`
                  )}%20${encodeURIComponent(event?.attributes?.redirection_URL)}`,
                  "_blank"
                );
                break;
              default:
                break;
            }

            const payload = {
              metric_id: event?.id,
              metric_type: "event",
              share_count: 1,
            };
            try {
              const response = await axios.post(
                `${config.apiUrl}api/metrics/save`,
                payload,
                {
                  headers: config.headers,
                }
              );
              if (response?.data?.statusCode === 201) {
                fetchData();
              }
            } catch (error) {
              console.error("Error saving metrics:", error);
            }
          };

          const formattedDate = eventDate?.toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
          });

          return (
            <Col span={24} md={8} key={index}>
              {contextHolder}
              <div
                className="storiesCard eventCard p-5 border rounded-md dark-gray  mb-6"
                onClick={handleCardClick}
                key={event?.id}
                style={{ borderTop: `8px solid ${borderColor}` }}
              >
                <div>
                  <div className="flex justify-between mb-3">
                    <div>
                      <Tag color="green">{event?.attributes?.pricing}</Tag>
                    </div>
                    <div
                      onClick={(event) => {
                        event?.preventDefault();
                      }}
                    >
                      {userRole && userRole === "Admin" ? (
                        <div
                          onClick={(event) => event?.stopPropagation()}
                          className="w-8 flex justify-end"
                        >
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  icon: (
                                    <PencilAltOutline
                                      color="#1F2937"
                                      size={16}
                                    />
                                  ),
                                  label: "Edit",
                                  onClick: handleEditClick,
                                },
                                {
                                  key: "2",
                                  icon: (
                                    <TrashOutline color="#1F2937" size={16} />
                                  ),
                                  label: "Delete",
                                  onClick: handleDeleteClick,
                                },
                              ],
                            }}
                            placement="bottomRight"
                          >
                            <div
                              className="cursor-pointer"
                              onClick={(event) => event?.stopPropagation()}
                            >
                              <span className="ant-dropdown-link">
                                <DotsVerticalOutline
                                  onClick={(event) => event?.stopPropagation()}
                                />
                              </span>
                            </div>
                          </Dropdown>
                        </div>
                      ) : (
                        <div
                          onClick={(event) => event?.stopPropagation()}
                          className="flex items-center gap-3"
                        >
                          <Dropdown
                            menu={{
                              items,
                            }}
                          >
                            <Space>
                              <Share size={22} />
                            </Space>
                          </Dropdown>
                          {userToken &&
                            <div className="cursor-pointer">
                              {event?.isBookmarked === false ? (
                                <BookmarkOutline onClick={postBookmark} />
                              ) : (
                                <img
                                  src={saved2}
                                  alt="post fav"
                                  className="h-6"
                                  onClick={removeBookmark}
                                />
                              )}
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  <h4 className="text-lg font-semibold text-black  mb-2 content-threeline content-twoline capitalize">
                    {event?.attributes?.event_Name}
                  </h4>
                  <div className="flex flex-row gap-8 mb-3">
                    <div className="flex gap-2 items-center">
                      {event?.attributes?.vendor?.data?.attributes
                        ?.vendor_Logo && (
                          <div className="event-round-full partner-img">
                            <img
                              src={
                                event?.attributes?.vendor?.data?.attributes
                                  ?.vendor_Logo?.data[0]?.attributes?.url
                              }
                              alt=""
                            />
                          </div>
                        )}
                      <div className="midnight-blue content-threeline content-oneline">
                        <div className="Grayish">Hosted by</div>
                        {event?.attributes?.vendor?.data?.attributes
                          ?.vendor_Name && (
                            <div className="content-threeline content-oneline capitalize font-medium">
                              {
                                event?.attributes?.vendor?.data?.attributes
                                  ?.vendor_Name
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 flex gap-2 flex-wrap items-center">
                    <CalendarOutline size={18} className="Grayish" />
                    <span className="content-threeline content-oneline">
                      {formattedDate}
                    </span>
                  </div>
                  <div className="mb-3 flex gap-2  items-center">
                    <LocationMarkerOutline
                      size={18}
                      className="Grayish w-4 min-w-[18px]"
                    />
                    <div className="content-threeline content-oneline capitalize">
                      {event?.attributes?.country} {event?.attributes?.located && ","} {event?.attributes?.located}
                    </div>
                  </div>
                </div>
                {userRole === "Admin" ? (
                  <div className="flex flex-row gap-5 text-sm items-center steel-gray mt-auto">
                    <div className="flex flex-row items-center gap-1">
                      <ArrowUpRight width={22} />
                      {event?.view_count === null ? 0 : event?.view_count}
                    </div>
                    <div className="flex flex-row items-center gap-1">
                      <BookmarkOutline size={18} />
                      {event?.fav_count === null ? 0 : event?.fav_count}
                    </div>
                    <div className="flex flex-row items-center gap-1">
                      <Share size={18} />
                      {event?.share_count === null ? 0 : event?.share_count}
                    </div>
                  </div>
                ) : (
                  <a
                    href={
                      event?.attributes?.redirection_URL.startsWith("https")
                        ? event?.attributes?.redirection_URL
                        : `https://${event?.attributes?.redirection_URL}`
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block w-full mt-auto"
                  >
                    <div className="purple-saas22 flex gap-1 flex-wrap items-center cursor-pointer">
                      Register Now <ArrowUpRight />
                    </div>
                  </a>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
      <SignInPopup
        visible={showSignInPopup}
        onClose={() => setShowSignInPopup(false)}
      />
      {isOpenConfirmationModal && (
        <ConfirmationModal
          handleOk={handleOk}
          handleCancel={handleCancel}
          selectedProjectId={selectedProjectId}
          open={isOpenConfirmationModal}
          modalDesc="Are you sure want to delete this event?"
        />
      )}
    </>
  );
};
