import moment from 'moment';
import TableAntd from '../../../components/table';
import { dateFormat } from '../../../utils';
import { DatePicker } from 'antd';
import SearchInput from '../../../components/SearchInput';
import SearchEmpty from '../../../components/searchEmpty';

const { RangePicker } = DatePicker;

function TableRegister({
    setSelectedDateRange,
    setSearchQuery,
    selectedDateRange,
    requestData,
    requestDataTotal
}) {

    const columns = [
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
        },
        {
            title: "Employee Count",
            dataIndex: "employee_count",
            key: "employee_count",
        },
        {
            title: "Industry",
            dataIndex: "industry",
            key: "industry",
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            render: (date) => moment(date).format("DD MMM YYYY"),
        },
        {
            title: "Created by",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (createdBy) => `${createdBy.firstName} ${createdBy.lastName}`,
        },
    ];


    const handleDateRangeChange = (dates) => {
        const newDates = Array.isArray(dates) ? dates : [];
        setSelectedDateRange(newDates);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div>
            <div className='flex gap-2 justify-between  items-center mb-2 '>
                <div className='w-1/4'>
                    <SearchInput
                        placeholder="Search by company name and email"
                        onChange={handleSearchChange}
                        className="mb-4"
                    />
                </div>
                <div className='mb-4 event-page'>
                    <RangePicker
                        value={selectedDateRange}
                        format={dateFormat}
                        className="range-picker"
                        onChange={handleDateRangeChange}
                        allowClear={selectedDateRange.length > 0}
                    />
                </div>
            </div>

            <TableAntd
                dataSource={requestData}
                columns={columns}
                locale={{ emptyText: <SearchEmpty filteredProduct={requestDataTotal} /> }}
            />
        </div>
    );
}

export default TableRegister;