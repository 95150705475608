import React, { useCallback } from 'react';
import {
    useEffect,
    useState
} from "react";
import { Spin } from 'antd';
import Tasks from '../../../components/kanban';
import { fetchDeals } from '../apiService';
import { useParams } from 'react-router-dom';
import { useUserRole } from '../../../context/userContext';

function FunnelViewDeals({
    searchQuery,
    selectedOrigin,
    selectedDateRange,
    loading,
    setLoading,
    requestDataTotal
}) {
    const [tasks, setTasks] = useState([]);
    const userRole = useUserRole();
    const { id } = useParams();

    const localStorageLabel = localStorage?.getItem('companyName');

    const [columns,] = useState([
        { id: "187099438", name: "Requirement Gathered", order: "187099438" },
        { id: "187099439", name: "Evaluation", order: "187099439" },
        { id: "187099440", name: "Demo Scheduled", order: "187099440" },
        { id: "187157302", name: "Demo Completed", order: "187157302" },
        { id: "187099441", name: "Opportunity", order: "187099441" },
        { id: "187099442", name: "Proposal Sent", order: "187099442" },
        { id: "187157303", name: "Verbal Commit", order: "187157303" },
        { id: "187157304", name: "On Hold", order: "187157304" },
        { id: "187099443", name: "Closed won", order: "187099443" },
        { id: "187099444", name: "Closed lost", order: "187099444" },
    ]);

    const getStatusMatch = (leadStage) => {
        switch (leadStage) {
            case "Requirement Gathered": return "187099438";
            case "Evaluation": return "187099439";
            case "Demo Scheduled": return "187099440";
            case "Demo Completed": return "187157302";
            case "Opportunity": return "187099441";
            case "Proposal Sent": return "187099442";
            case "Verbal Commit": return "187157303";
            case "On Hold": return "187157304";
            case "Closed won": return "187099443";
            case "Closed lost": return "187099444";
            default: return "187099441";
        }
    };

    const fetchTasks = useCallback(async () => {
        setLoading(true);
        try {
            const { data: tickets } = await fetchDeals(id, selectedOrigin, selectedDateRange, searchQuery, userRole, localStorageLabel);
            if (Array.isArray(tickets)) {
                const formattedTasks = tickets.map((ticket) => ({
                    id: ticket?.id,
                    col_id: getStatusMatch(ticket?.properties?.stageName),
                    contact_name: ticket?.properties?.firstname + " " + ticket?.properties?.lastname || "N/A",
                    priority_id: 1,
                    deal_amount: ticket?.properties?.amount || 0,
                    closed_date: ticket?.properties?.closedate || null,
                    deal_name: ticket?.properties?.dealname || "N/A",
                    vendor_id: id,
                    email: ticket?.properties?.contactEmail || null,
                    hs_object_id: ticket?.properties?.hs_object_id || null,
                    deal_kanban_view: true,
                    firstname: ticket?.properties?.firstname || null,
                }));

                setTasks(formattedTasks);
            } else {
                console.error('Fetched data is not an array:', tickets);
                setTasks([]);
            }
        } catch (error) {
            console.error('Failed to fetch tasks', error);
            setTasks([]);
        } finally {
            setLoading(false);
        }

    }, [setLoading, id, selectedOrigin, selectedDateRange, searchQuery, userRole, localStorageLabel]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    return (
        <div>
            {loading ?
                <div className='flex items-center justify-center h-[200px]'>
                    <Spin className='loader' tip='Loading...' />
                </div>
                :
                <Tasks tasks={tasks} columns={columns} dealsview={true} tasksTotal={requestDataTotal} userRole={userRole} />
            }
        </div>
    );
}

export default FunnelViewDeals;