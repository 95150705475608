import {
    Button,
    Form,
} from 'antd';
import { ChevronLeftOutline } from 'heroicons-react';
import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import TableRegister from './table';
import { RegisterNewLeadDrawer } from './registerNewLeadDrawer';
import {
    fetchLeads,
    registerLead
} from '../apiService';
import { getSyncDetails } from '../vendorDetail';
import { useUserRole } from '../../../context/userContext';

function LeadRegister() {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [, setLoading] = useState(false);
    const [syncDetails, setSyncDetails] = useState(null);
    const [requestData, setRequestData] = useState();
    const [requestDataTotal, setRequestDataTotal] = useState(0);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchLeads(id, selectedDateRange, searchQuery || null, userRole, localStorageLabel);
            setRequestData(data?.data);
            setRequestDataTotal(data?.pagination?.totalCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [id, localStorageLabel, searchQuery, selectedDateRange, userRole]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
    }, [id, localStorageLabel, userRole]);

    const handleSubmit = async (values) => {
        try {
            await registerLead(id, values, setIsSubmitting, form, setDrawerVisible);
            await getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
            await fetchData();
        } catch (error) {
            console.error("Error handling submit:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className='flex items-center justify-between gap-2 mb-5'>
                <div className='flex flex-row gap-2 items-center'>
                    <div onClick={() => navigate(-1)} className='cursor-pointer'>
                        <ChevronLeftOutline />
                    </div>
                    {syncDetails?.vendor &&
                        <div>
                            <div className='flex flex-row gap-2 items-center'>
                                <div className='text-base Grayish'>{syncDetails?.vendor} &nbsp;•</div>
                                {userRole === "Admin" && <div className='text-base Grayish'>{localStorageLabel} &nbsp;•</div>}

                                <div className='text-black font-medium text-base'>Registered leads</div>
                            </div>
                        </div>
                    }
                </div>
                {userRole === "Partner" &&
                    <div className='flex flex-row gap-3 purple-saas22 font-semibold'>
                        <Button type="primary" onClick={() => setDrawerVisible(true)}>
                            Register a lead
                        </Button>
                    </div>
                }
            </div>
            <RegisterNewLeadDrawer
                visible={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                handleSubmit={handleSubmit}
                Form={form}
                isSubmitting={isSubmitting}
            />
            <TableRegister
                setSelectedDateRange={setSelectedDateRange}
                setSearchQuery={setSearchQuery}
                selectedDateRange={selectedDateRange}
                requestData={requestData}
                requestDataTotal={requestDataTotal}
            />
        </>
    );
}

export default LeadRegister;