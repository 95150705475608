import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import config from "../../confiq/confiq";
import {  useNavigate } from "react-router-dom";
import moment from "moment";
import StatusTag from "../../components/statusTag";
import SavedEmpty from "../../components/SavedEmpty";

const MyRequest = () => {
  const [requestData, setRequestData] = useState([]);
  const [, setLoading] = useState(true);

  const fetchRequestData = () => {
    axios.get(`${config.apiUrl}api/user/getTickets`, {
      headers: config.headers,
    }).then((response) => {
      setRequestData(response?.data?.data);
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchRequestData();
  }, []);

  const statusOptions = [
    { value: 2, label: 'Open', className: 'blue-tag' },
    { value: 3, label: 'Pending', className: 'pending-tag' },
    { value: 4, label: 'Resolved', className: 'approved-tag' },
    { value: 5, label: 'Closed', className: 'revoked-tag' },
  ];

  const getStatusLabelAndClass = (status) => {
    const statusOption = statusOptions.find(option => option.value === status);
    return statusOption ? (
      <StatusTag status={statusOption.label} className={statusOption.className} />
    ) : null;
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Start Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        const formattedDate = moment(date).format("DD-MMM-YYYY");
        return formattedDate;
      },
    },
    {
      title: "Budget Range",
      dataIndex: "currency",
      key: "currency",
      render: (currency, value) => <div>{currency} {value?.budget}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => getStatusLabelAndClass(status),
    },
  ];
  const handleRowClick = (record) => {
    navigate(`/projects/my-request/${record.id}`);
  };

  return (
    <div className={`min-h-[50vh]  `}>
      <div style={{ overflowX: "auto" }}>
        <Table
          dataSource={requestData}
          locale={{ emptyText: <div className="w-40 -mt-14 m-auto"> <SavedEmpty /></div> }}
          columns={columns} scroll={{ x: true }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </div>
    </div>

  );
};

export default MyRequest;