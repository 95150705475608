import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import { Button, Tabs } from 'antd';
import { All } from './all';
import { Saved } from './saved';
import { useUserRole } from '../../context/userContext';
import "./index.scss";
import { useAuth } from '../../context';
import { Helmet } from 'react-helmet';

export default function Events() {
    const [strapiUrl, setStrapiUrl] = useState('');
    const userRole = useUserRole();
    const { userToken } = useAuth();
    const savedActiveKey = localStorage.getItem('eventActiveTabKey');
    const [activeKey, setActiveKey] = useState(savedActiveKey || '1');

    const allEventRef = useRef(null);
    const mostReadRef = useRef(null);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error('REACT_APP_STRAPI_URL is not defined in the environment.');
        }
    }, []);

    const items = [
        {
            key: '1',
            label: 'All',
            children: <All ref={allEventRef} />,
        },
        ...(userRole !== 'Admin' && userToken
            ? [
                {
                    key: '2',
                    label: 'Saved',
                    children: <Saved ref={mostReadRef} />,
                }]
            : []),
    ];

    const handleTabChange = (key) => {
        localStorage.setItem('eventActiveTabKey', key);
        setActiveKey(key);
        allEventRef.current && allEventRef.current.fetchData();
        mostReadRef.current && mostReadRef.current.fetchData();
    };

    const handleCreate = () => {
        window.open(`${strapiUrl}admin/content-manager/collectionType/api::event.event/create`);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Upcoming SaaS & AI Events | SaaS22.com</title>
                <meta name="description" content={"Explore curated SaaS & AI events on SaaS22. Join industry-leading webinars, workshops, and conferences to stay ahead in tech innovation."} />
            </Helmet>
            <div className='events-page'>
                <div className='block md:hidden mb-3'>
                    <div className='text-xl font-semibold'> Events</div>
                </div>
                <Tabs
                    activeKey={activeKey}
                    defaultActiveKey="1"
                    items={items}
                    className="primary-tab child-tab"
                    tabBarExtraContent={
                        userRole === 'Admin' && (
                            <Button type="primary" onClick={handleCreate}>
                                Create New Event
                            </Button>
                        )
                    }
                    onChange={handleTabChange}
                />
            </div>
        </>
    );
}

export const StaticContent = () => {
    return (
        <>
            <h1 className='text-[#7B7B7B] mb-3'>Discover the latest and greatest events happening world over in SaaS and AI
            </h1>
        </>
    )
}