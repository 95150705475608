import TableAntd from '../../components/table';
import moment from 'moment';
import {  useNavigate } from 'react-router-dom';

function TableView({ getFetchData, loading, tasksTotal }) {
    const navigate = useNavigate();

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Name",
            dataIndex: "buyer_name",
            key: "buyer_name",
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
        },
        {
            title: "title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            render: (date) => {
                const formattedDate = moment(date).format("DD MMM YYYY");
                return formattedDate;
            },
        },
    ];
    const handleRowClick = (record) => {
        navigate(`/request/detail/${record.id}`);
    };
    return (
        <div className='table-cursor'>
                <TableAntd
                    dataSource={getFetchData}
                    columns={columns}
                    searchEmpty={tasksTotal}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                />
        </div>
    );
}

export default TableView;