import React, { useEffect, useState } from 'react';
import { Menu, Skeleton } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
    AnnotationOutline,
    // BriefcaseOutline,
    DocumentTextOutline,
    HomeOutline,
    NewspaperOutline,
    UserOutline,
    UsersOutline,
} from 'heroicons-react';
import { useAuth } from '../../context';
import { DealsIcon, EventIcon, HandshakePartrner, pendingApproval } from '../../assets/img';
import { useUserRole } from '../../context/userContext';
import './Sidebar.scss';

function Sidebar(props) {
    const location = useLocation();
    const { userToken } = useAuth();
    const userRole = useUserRole();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);


    const buyerMenuItems = [
        ...(userRole && userToken
            ? [
                {
                    key: '/home',
                    icon: <HomeOutline />,
                    text: 'Home',
                },
                {
                    key: '/projects',
                    icon: <AnnotationOutline />,
                    text: 'Requests',
                },
            ]
            : []),
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
        },
        {
            key: '/events',
            icon: <img src={EventIcon} alt='events' />,
            text: 'Events',
        },
        ...(userRole && userToken
            ? [
                // {
                //     key: '/jobs',
                //     icon: <BriefcaseOutline />,
                //     text: 'Jobs',
                // },
                {
                    key: '/discount',
                    icon: <img src={DealsIcon} alt='events' />,
                    text: 'Discounts',
                },
                {
                    key: '/profile',
                    icon: <UserOutline />,
                    text: 'Profile',
                },
            ]
            : []),
    ];

    const partnerMenuItems = [
        ...(userRole && userToken
            ? [
                {
                    key: '/home',
                    icon: <HomeOutline />,
                    text: 'Home',
                },
                {
                    key: '/partnerships',
                    icon: <img src={HandshakePartrner} alt='events' className='h-[28px]' />,
                    text: 'Partnerships',
                },
                {
                    key: '/request',
                    icon: <AnnotationOutline />,
                    text: 'Requests',
                },
            ]
            : []),
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
        },
        {
            key: '/events',
            icon: <img src={EventIcon} alt='events' />,
            text: 'Events',
        },
        ...(userRole && userToken
            ? [
                {
                    key: '/discount',
                    icon: <img src={DealsIcon} alt='events' />,
                    text: 'Discounts',
                },
                {
                    key: '/profile',
                    icon: <UserOutline />,
                    text: 'Profile',
                },
            ]
            : []),
    ];

    const adminMenuItems = [
        ...(userRole && userToken
            ? [
                {
                    key: '/home',
                    icon: <HomeOutline />,
                    text: 'Home',
                },
                {
                    key: '/partnerships',
                    icon: <img src={HandshakePartrner} alt='events' className='h-[28px]' />,
                    text: 'Partnerships',
                },
            ]
            : []),
        // {
        //     key: '/projects',
        //     icon: <AnnotationOutline />,
        //     text: 'Projects',
        // },
        {
            key: '/resources',
            icon: <NewspaperOutline />,
            text: 'Resources',
        },
        {
            key: '/events',
            icon: <img src={EventIcon} alt='events' />,
            text: 'Events',
        },
        ...(userRole && userToken
            ? [
                // {
                //     key: '/jobs',
                //     icon: <BriefcaseOutline />,
                //     text: 'Jobs',
                // },
                {
                    key: '/discount',
                    icon: <img src={DealsIcon} alt='events' />,
                    text: 'Discounts',
                },
                {
                    key: '/users',
                    icon: <UsersOutline />,
                    text: 'Users',
                },
                {
                    key: '/content',
                    icon: <DocumentTextOutline />,
                    text: 'Content',
                },
                {
                    key: '/request',
                    icon: <img src={pendingApproval} alt='pending approval' />,
                    text: 'Requests',
                    link: 'https://saas22-help.freshdesk.com/support/login',
                },
            ]
            : []),
    ];

    const menuItems = userRole === 'Buyer'
        ? buyerMenuItems
        : userRole === 'Partner'
            ? partnerMenuItems
            : adminMenuItems;

    const isMenuItemActive = (menuItem) => {
        return location?.pathname?.startsWith(menuItem?.key);
    };

    const renderMenuItem = (item) => {
        if (item?.link) {
            return (
                <Menu.Item key={item?.key} className="custom-menu-item">
                    <a href={item?.link} target={'_blank'} rel="noopener noreferrer">
                        <div className="flex flex-col gap-1 items-center">
                            <div>{item?.icon}</div>
                            <div>{item?.text}</div>
                        </div>
                    </a>
                </Menu.Item>
            );
        } else {
            return (
                <Menu.Item key={item?.key} className="custom-menu-item">
                    <Link to={item?.key}>
                        <div className="flex flex-col gap-1 items-center">
                            <div>{item?.icon}</div>
                            <div>{item?.text}</div>
                        </div>
                    </Link>
                </Menu.Item>
            );
        }
    };

    return (
        <div className={`sidebar-components ${props?.isActive ? '' : 'active'}`}>
            <div className={`hideSidebar ${props?.isActive ? '' : 'active'}`}>
                <div className='w-28 bg-black sidebar-scroll-set'>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <Menu
                            mode="vertical"
                            theme="light"
                            selectedKeys={menuItems?.filter(isMenuItemActive).map(item => item?.key)}
                        >
                            {menuItems?.map((item) => renderMenuItem(item))}
                        </Menu>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;