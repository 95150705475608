import { ChevronLeftOutline } from 'heroicons-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import { getSyncDetails } from '../vendorDetail';
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Spin,
    message
} from 'antd';
import {
    getUserDetails,
    createNote,
    editNote,
    deleteNote,
    fetchLeadQualified,
    fetchSqlStages,
    fetchNotes,
    fetchDealStages,
    getAllNotes,
} from '../apiService';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import NotesRequest from './notesHubspot';
import config from '../../../confiq/confiq';
import axiosInstance from '../../../confiq/axiosInstance';
import dayjs from 'dayjs';
import { useUserRole } from '../../../context/userContext';

const { Option } = Select;

function ViewContactDetailPage() {
    const [syncDetails, setSyncDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [textAreaHeightOverall, setTextAreaHeightOverall] = useState(null);
    const [textAreaHeight, setTextAreaHeight] = useState(35);
    const [bottomNotesVisible, setBottomNotesVisible] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [textAreaValueNotes, setTextAreaValueNotes] = useState('');
    const [, setSqlStages] = useState([]);
    const [dealStages, setDealStages] = useState([]);
    const [, setLeadQualified] = useState([]);
    const [notes, setNotes] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    const [formnotes] = Form.useForm();
    const [form] = Form.useForm();
    const { id, email } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { labelText } = location.state || {};
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');
    const [fetchNote, setFetchNote] = useState(null);

    const fetchDetails = async () => {
        try {
            const details = await getUserDetails(email, id, localStorageLabel, userRole);
            setUserDetails(details);
            if (details && details[0]) {
                const formattedDetails = {
                    ...details[0].properties,
                };
                form.setFieldsValue(formattedDetails);
                const notesResponse = await getAllNotes(id, details[0].properties.hs_object_id);
                setNotes(notesResponse?.data || []);
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
        fetchDetails();

        const fetchAllData = async () => {
            try {

                const leadQualifiedResponse = await fetchLeadQualified(id);
                setLeadQualified(leadQualifiedResponse || []);

                const sqlStagesResponse = await fetchSqlStages(id);
                setSqlStages(sqlStagesResponse || []);

                const dealStagesResponse = await fetchDealStages(id);
                setDealStages(dealStagesResponse || []);
            } catch (error) {
                console.error("Error fetching additional data:", error);
            }
        };
        fetchAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, id, form]);

    const user = userDetails && userDetails[0];
    const firstName = user?.properties?.firstname || '';
    const lastName = user?.properties?.lastname || '';
    const fullName = `${firstName} ${lastName}`.trim();


    const fetchNotes = useCallback(() => {
        if (user) {
            axiosInstance.get(`${config.apiUrl}api/hubspot/getAllCreatedNote?vendor_id=${id}&contactId=${user?.id}`, {
                headers: config.headers,
            }).then((response) => {
                setFetchNote(response?.data?.data);
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
        }

    }, [user, id]);

    useEffect(() => {
        fetchNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchNotes]);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleFormSubmit = async (values) => {
        const formattedDate = values?.dealClosedateForm2 ? values.dealClosedateForm2.format("YYYY-MM-DD")
            : user?.properties?.dealClosedate ? dayjs(user?.properties?.dealClosedate).format("YYYY-MM-DD") : '';

        const formattteddatedemo = values?.demo_given_date_form2 ?
            values.demo_given_date_form2.format("YYYY-MM-DD") :
            user?.properties?.demo_given_date ? dayjs(user?.properties?.demo_given_date).format("YYYY-MM-DD")
                : '';


        const contactPayload = {
            "vendor_id": parseInt(id),
            "email": email,
            "hs_object_id": user?.properties?.hs_object_id,
            "contact_established": values?.contact_established === "No" ? false : values?.contact_established === "Yes" ? true : user?.properties?.contact_established,
            "lead_qualification": values?.lead_qualification || user?.properties?.lead_qualification,
            "company_account": values?.company_account || user?.properties?.company_account,
            "sql_status": values?.sql_status || user?.properties?.sql_status,
            "demo_booked": values?.demo_booked === "No" ? false : values?.demo_booked === "Yes" ? true : user?.properties?.demo_booked,
            "demo_given_date": formattteddatedemo,
            "employee_count": values?.employee_count || user?.properties?.employee_count,
            "industry": values?.industry || user?.properties?.industry,
            "jobtitle": values?.jobtitle || user?.properties?.jobtitle,
            "dealId": user?.properties?.dealId,
            "dealAmount": values?.dealAmount ? values.dealAmount.toString() : user?.properties?.dealAmount,
            "dealClosedate": formattedDate,
            "dealStage": values?.dealStageID ||
                (user?.properties?.dealStage ? dealStages?.find(stage => stage.label === user?.properties?.dealStage)?.id : undefined),
        }

        const hasChanges = Object.keys(contactPayload).some(key => {
            return contactPayload[key] !== user?.properties[key];
        });

        setLoading(true);

        try {
            const contactResponse = await axiosInstance.post(`api/hubspot/update`, contactPayload, {
                headers: config.headers,
            });

            if (contactResponse?.data?.statusCode !== 201) {
                throw new Error('Failed to update contact');
            }

            if (hasChanges && contactResponse?.data?.statusCode === 201) {
                // window.location.reload();
                fetchDetails();
                message.success('Contact updated successfully');
            }

            setIsEditing(false);
        } catch (error) {
            console.error("Error updating contact:", error);
            message.error(error.message || 'Failed to update contact');
        } finally {
            setLoading(false);
            setIsEditing(false);
        }
    };

    const handleCreateNote = async (noteBody) => {
        try {
            setLoading(true);
            await createNote(id, user?.properties?.hs_object_id, noteBody);
            message.success('Note created successfully');
            fetchNotes(id, user && user?.properties?.hs_object_id);
        } catch (error) {
            console.error("Error creating note:", error);
            message.error('Failed to create note');
        } finally {
            setLoading(false);
        }
    };

    const handleEditNote = async (noteId, newBody) => {
        try {
            setLoading(true);
            await editNote(id, noteId, newBody);
            message.success('Note edited successfully');
            fetchNotes(id, user && user?.properties?.hs_object_id);
        } catch (error) {
            console.error("Error editing note:", error);
            message.error('Failed to edit note');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteNote = async (noteId) => {
        try {
            setLoading(true);
            await deleteNote(id, noteId);
            message.success('Note deleted successfully');

        } catch (error) {
            console.error("Error deleting note:", error);
            message.error('Failed to delete note');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        formnotes.resetFields();
        setTextAreaHeight(35);
        setBottomNotesVisible(false);
        setEditingNoteId(null);
    };

    const handleTextAreaFocus = () => {
        setTextAreaHeight(140);
        setBottomNotesVisible(true);
    };

    const handleOverall = () => {
        if (textAreaHeightOverall === null || textAreaHeightOverall !== 250) {
            setTextAreaHeightOverall(250);
            setTextAreaHeight(140);
            setBottomNotesVisible(true);
        } else {
            setTextAreaHeightOverall(null);
            setTextAreaHeight(35);
            setBottomNotesVisible(false);
            setEditingNoteId(null);
        }
    };

    const initialValues = useMemo(() => ({
        contact_established: user?.properties?.contact_established === "true" || user?.properties?.contact_established === true ? 'Yes' : 'No',
        lead_qualification: user?.properties?.lead_qualification || '',
        company_account: user?.properties?.company_account || '',
        sql_status: user?.properties?.sql_status || '',
        demo_booked: user?.properties?.demo_booked === "true" ? 'Yes' : 'No',
        demo_given_date_form: user?.properties?.demo_given_date,
        employee_count: user?.properties?.employee_count || '',
        industry: user?.properties?.industry || '',
        jobtitle: user?.properties?.jobtitle || '',
        dealAmount: user?.properties?.dealAmount || '',
        dealStage: user?.properties?.dealStage || '',
        dealClosedateForm: user?.properties?.dealClosedate,
    }), [user?.properties]);

    useEffect(() => {
        // Ensure initialValues are set before form renders
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    const compareValues = (currentValues) => {
        return JSON.stringify(initialValues) === JSON.stringify(currentValues);
    };

    const formatDemoGivenDate = (date) => {
        const timestamp = Number(date);
        if (!isNaN(timestamp) && date.length === 13) {
            return moment(timestamp).format("DD MMM YYYY");
        } else if (moment(date, moment.ISO_8601, true).isValid()) {
            return moment(date).format("DD MMM YYYY");
        }
        return 'Invalid date';
    };

    const getDatePickerValue = (dateValue) => {
        if (!dateValue) return null;

        const timestamp = Number(dateValue);
        if (!isNaN(timestamp) && dateValue.length === 13) {
            return dayjs(timestamp);
        } else if (moment(dateValue, moment.ISO_8601, true).isValid()) {
            return dayjs(dateValue);
        }
        return null;
    };

    return (
        <div>
            {loading ?
                <Spin className='loader' tip='Loading...' />
                :
                <>
                    <Row gutter={16} >
                        <Col span={16}>
                            <div className='flex flex-row gap-2 items-center mb-2'>
                                <div onClick={() => navigate(-1)} className='cursor-pointer'>
                                    <ChevronLeftOutline />
                                </div>
                                <div>
                                    <div className='flex flex-row gap-2 items-center'>
                                        <div className='text-base Grayish'>{syncDetails?.vendor} &nbsp;•</div>
                                        {userRole === "Admin" && <div className='text-base Grayish'>{localStorageLabel} &nbsp;•</div>}
                                        <div className='text-base Grayish'>{labelText || 'Contacts'}&nbsp;•</div>
                                        <div className='text-black font-medium text-base'>{fullName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='edit-properties-firstsection custom-scrollerbar2'>
                                <div className='mb-5'>
                                    <Row gutter={[0, 16]} className='text-sm bg-white row-section p-4 px-2 mt-2 '>
                                        <ContactInfoItem
                                            label="Full Name" value={`${user?.properties?.firstname || ''} ${user?.properties?.lastname || ''}`.trim()} />
                                        <ContactInfoItem
                                            label="Email" value={
                                                <>
                                                    {user?.properties?.email || 'N/A'}
                                                    {user?.properties?.email && <CheckCircleOutlined style={{ marginLeft: '5px', color: 'green' }} />}
                                                </>
                                            }
                                        />
                                        <ContactInfoItem
                                            label="Phone Number"
                                            value={user?.properties?.phone || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Alternate Phone Number"
                                            value={user?.properties?.alternate_phone || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Company Name"
                                            value={user?.properties?.company || 'N/A'}
                                        />
                                         <ContactInfoItem
                                            label="Country"
                                            value={user?.properties?.country || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Potential Plan"
                                            value={user?.properties?.potential_plan || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Lead Source"
                                            value={user?.properties?.lead_source || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="First Lead Source"
                                            value={user?.properties?.first_lead_source || 'N/A'}
                                        />
                                        <Col span={12} >
                                            <div className='text-sm' >
                                                <div className='text-[#7B7B7B] mb-1'>{"Pre-Signup URL"}</div>
                                                <div className='text-black'>
                                                    {user?.properties?.pre_signup_url ? (
                                                        <a href={user.properties.pre_signup_url.startsWith("https")
                                                            ? user.properties.pre_signup_url
                                                            : `https://${user.properties.pre_signup_url}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 hover:underline">
                                                            Click here
                                                        </a>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12} >
                                            <div className='text-sm' >
                                                <div className='text-[#7B7B7B] mb-1'>{"First Page Seen"}</div>
                                                <div className='text-black'>
                                                    {user?.properties?.hs_analytics_first_url ? (
                                                        <a href={user.properties.hs_analytics_first_url.startsWith("https")
                                                            ? user.properties.hs_analytics_first_url
                                                            : `https://${user.properties.hs_analytics_first_url}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 hover:underline">
                                                            Click here
                                                        </a>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <ContactInfoItem
                                            label="Campaign Name"
                                            value={user?.properties?.campaign_name || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Ad Group"
                                            value={user?.properties?.ad_group || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Campaign Source"
                                            value={user?.properties?.campaign_source || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Keyword"
                                            value={user?.properties?.keyword || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Campaign Medium"
                                            value={user?.properties?.campaign_medium || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Onboarding Use Case"
                                            value={user?.properties?.onboarding_use_case || 'N/A'}
                                        />
                                        <ContactInfoItem
                                            label="Trial Enabled Features"
                                            value={user?.properties?.trial_enabled_features ?
                                                user.properties.trial_enabled_features.split(/[,;]/).map(word =>
                                                    word.trim().split('_').map(subWord =>
                                                        subWord.charAt(0).toUpperCase() + subWord.slice(1)
                                                    ).join(' ')
                                                ).join(', ')
                                                : 'N/A'
                                            }
                                        />
                                        <ContactInfoItem
                                            label="Trial End Date"
                                            value={user?.properties?.trial_end_date ? moment(user?.properties?.trial_end_date).format("DD MMM YYYY") : 'N/A'}
                                        />
                                    </Row>
                                </div>
                                <div className="bottom-0 w-full pr-5 notes-collapse ">
                                    <NotesRequest
                                        id={id}
                                        setLoading={setLoading}
                                        textAreaHeightOverall={textAreaHeightOverall}
                                        setTextAreaHeightOverall={setTextAreaHeightOverall}
                                        form={formnotes}
                                        textAreaValue={textAreaValueNotes}
                                        setTextAreaValue={setTextAreaValueNotes}
                                        textAreaHeight={textAreaHeight}
                                        setTextAreaHeight={setTextAreaHeight}
                                        bottomNotesVisible={bottomNotesVisible}
                                        setBottomNotesVisible={setBottomNotesVisible}
                                        handleOverall={handleOverall}
                                        handleCancel={handleCancel}
                                        editingNoteId={editingNoteId}
                                        setEditingNoteId={setEditingNoteId}
                                        handleTextAreaFocus={handleTextAreaFocus}
                                        onCreateNote={handleCreateNote}
                                        onEditNote={handleEditNote}
                                        onDeleteNote={handleDeleteNote}
                                        notes={notes}
                                        contactId={user && user?.id}
                                        fetchNote={fetchNote}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="bg-white rounded-lg edit-properties">
                                <div className="flex justify-between items-center p-5 pb-2">
                                    <h3 className="text-lg font-semibold">{userRole === "Partner" && "Edit"} Properties</h3>
                                    {userRole === "Partner" &&
                                        <div
                                            type="primary"
                                            onClick={() => {
                                                if (isEditing && isChanged) {
                                                    form?.submit();
                                                } else if (!isEditing) {
                                                    handleEditClick();
                                                }
                                            }}
                                            className={`purple-saas22 text-base ${!isEditing || isChanged ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}`}
                                        >
                                            {isEditing ? 'Save' : 'Edit'}
                                        </div>
                                    }
                                </div>
                                <Divider className='mb-2 mt-2 ' />
                                <div className='p-5 pt-2 edit-properties2 custom-scrollerbar2'>
                                    {isEditing ? (
                                        <>
                                            <Form
                                                form={form}
                                                layout="vertical"
                                                onFinish={handleFormSubmit}
                                                initialValues={initialValues}
                                                onValuesChange={(changedValues, allValues) => {
                                                    if (compareValues(allValues)) {
                                                        setIsChanged(false);
                                                    } else {
                                                        setIsChanged(true);
                                                    }
                                                }}
                                            >
                                                <Form.Item name="contact_established" label="Contact Established">
                                                    <Select defaultValue={initialValues.contact_established}>
                                                        <Option value="Yes">Yes</Option>
                                                        <Option value="No">No</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="lead_qualification" label="Lead Qualification">
                                                    <Select>
                                                        <Option value="SQL">SQL</Option>
                                                        <Option value="MQL">MQL</Option>
                                                        <Option value="Subscriber">Subscriber</Option>
                                                        <Option value="Thank you page Sign up">Thank you page Sign up</Option>
                                                        <Option value="JUNK">JUNK</Option>
                                                        <Option value="Outbound">Outbound</Option>
                                                        <Option value="Repeat">Repeat</Option>
                                                        <Option value="Student Sign-ups">Student Sign-ups</Option>
                                                        <Option value="Gmail to Hubspot">Gmail to Hubspot</Option>
                                                        <Option value="Competitor">Competitor</Option>
                                                        <Option value="Chat">Chat</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="company_account" label="Company Account">
                                                    <Select defaultValue={initialValues?.company_account}>
                                                        <Option value="Yes">Yes</Option>
                                                        <Option value="No">No</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="sql_status" label="SQL Status">
                                                    <Select>
                                                        <Option value="Opportunity">Opportunity</Option>
                                                        <Option value="Exploring">Exploring</Option>
                                                        <Option value="No requirement">No requirement</Option>
                                                        <Option value="Not Interested">Not Interested</Option>
                                                        <Option value="No Budget">No Budget</Option>
                                                        <Option value="Wants Free Plan">Wants Free Plan</Option>
                                                        <Option value="Window Shopping">Window Shopping</Option>
                                                        <Option value="One time Use-Case">One time Use-Case</Option>
                                                        <Option value="Unaware of Platform">Unaware of Platform</Option>
                                                        <Option value="Feature Not Available">Feature Not Available</Option>
                                                        <Option value="Lost to competition">Lost to competition</Option>
                                                        <Option value="Language Barrier">Language Barrier</Option>
                                                        <Option value="Job candidate">Job candidate</Option>
                                                        <Option value="Panel Requirement">Panel Requirement</Option>
                                                        <Option value="Partner">Partner</Option>
                                                        <Option value="Others">Others</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="demo_booked" label="Demo Status">
                                                    <Select defaultValue={initialValues?.demo_booked === "false" ? "No" : "Yes"}>
                                                        <Option value="Yes">Yes</Option>
                                                        <Option value="No">No</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name="demo_given_date_form2"
                                                    label="Demo Given Date"
                                                >
                                                    <DatePicker
                                                        className='w-full'
                                                        defaultValue={getDatePickerValue(initialValues.demo_given_date_form)}
                                                        value={getDatePickerValue(initialValues.demo_given_date_form)}
                                                        onChange={(date) => form.setFieldsValue({ demo_given_date_form: date ? date.valueOf() : null })} // Store as Unix timestamp in milliseconds
                                                    />
                                                </Form.Item>
                                                <Form.Item name="employee_count" label="Employee Count">
                                                    <Select placeholder="Select employee count">
                                                        <Option value="Personal">Personal</Option>
                                                        <Option value="1-10">1-10</Option>
                                                        <Option value="11-50">11-50</Option>
                                                        <Option value="51-200">51-200</Option>
                                                        <Option value="201-500">201-500</Option>
                                                        <Option value="501-1000">501-1000</Option>
                                                        <Option value="1001-5000">1001-5000</Option>
                                                        <Option value="5000-10000">5000-10000</Option>
                                                        <Option value="10001+">10001+</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="industry" label="Industry">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="jobtitle" label="Job Title">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="dealAmount" label="Deal Amount">
                                                    <InputNumber
                                                        className='w-full'
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value?.replace(/\$\s?|(,*)/g, '')}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="dealStageID" hidden>
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Form.Item name="dealStage" label="Deal Stage">
                                                    <Select
                                                        onChange={(value, option) => {
                                                            form.setFieldsValue({
                                                                dealStage: value,
                                                                dealStageID: option?.key,
                                                            });
                                                        }}
                                                    >
                                                        {dealStages && dealStages?.filter(stage => stage.label !== "Logged by partner")
                                                            .map(stage => (
                                                                <Option key={stage?.id} value={stage?.label}>
                                                                    {stage?.label}
                                                                </Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name="dealClosedateForm2"
                                                    label="Deal Close Date"
                                                >
                                                    <DatePicker
                                                        className='w-full'
                                                        defaultValue={getDatePickerValue(initialValues.dealClosedateForm)}
                                                        value={getDatePickerValue(initialValues.dealClosedateForm)}
                                                        onChange={(date) => form.setFieldsValue({ dealClosedateForm: date ? date.valueOf() : null })} // Store as Unix timestamp in milliseconds
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </>
                                    ) : (
                                        <>
                                            <Row gutter={[0, 16]}>
                                                <ContactInfoItem label="Contact Established"
                                                    value={user?.properties?.contact_established === "true" || user?.properties?.contact_established === true ? 'Yes' : 'No'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Lead Qualification"
                                                    value={user?.properties?.lead_qualification || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Company Account"
                                                    value={user?.properties?.company_account || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="SQL Status"
                                                    value={user?.properties?.sql_status || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Demo Status"
                                                    value={user?.properties?.demo_booked === "true" ? 'Yes' : 'No'}
                                                    span={24}
                                                />
                                                <ContactInfoItem
                                                    label="Demo Given Date"
                                                    value={
                                                        user?.properties?.demo_given_date
                                                            ? formatDemoGivenDate(user?.properties?.demo_given_date)
                                                            : 'N/A'
                                                    }
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Employee Count"
                                                    value={user?.properties?.employee_count || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Industry"
                                                    value={user?.properties?.industry || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Job Title"
                                                    value={user?.properties?.jobtitle || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Deal Amount"
                                                    value={user?.properties?.dealAmount ? `$${user.properties.dealAmount}` : 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem label="Deal Stage"
                                                    value={user?.properties?.dealStage || 'N/A'}
                                                    span={24}
                                                />
                                                <ContactInfoItem
                                                    label="Close Date"
                                                    value={
                                                        user?.properties?.dealClosedate
                                                            ? formatDemoGivenDate(user?.properties?.dealClosedate)
                                                            : 'N/A'
                                                    }
                                                    span={24}
                                                />
                                            </Row>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
}

export default ViewContactDetailPage;

const ContactInfoItem = ({ label, value, isLink = false, className, span }) => (
    <Col span={span || 12} className={className}>
        <div className='text-sm' >
            <div className='text-[#7B7B7B] mb-1'>{label}</div>
            <div className='text-black text-wrap break-words'>
                {isLink && value ? (
                    <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                        {value}
                    </a>
                ) : (
                    value || 'N/A'
                )}
            </div>
        </div>
    </Col>
);
