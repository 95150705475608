import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import SearchInput from "../../components/SearchInput";
import axios from "axios";
import SearchEmpty from "../../components/searchEmpty";
import Pagination from "../../components/paginationSet";
import config from "../../confiq/confiq";
import {
    Spin
} from "antd";
import { Deal } from "../../components/Card/deal";
import "./index.scss";
import FilterIcon, { ShowingText } from "../../components/filterIcon";
import DealDrawer, { DealFilter } from "./dealDrawer";

function Free({ props, ref }) {
    const [selectedBrandType, setSelectedBrandType] = useState("");
    const [category, setCategory] = useState("");

    const [product, setProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [dealBrandFilter, setDealBrandFilter] = useState(null);
    const [dealCategoryFilter, setDealCategoryFilter] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredProduct, setFilteredProduct] = useState();
    const [brands, setBrands] = useState([]);
    const [open, setOpen] = useState(false);

    const PAGE_SIZE = 15;

    const resetFilters = () => {
        setSelectedBrandType(null);
        setCategory(null);
        setDealCategoryFilter(null);
        setDealBrandFilter(null);
        fetchData();
    };

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${config.apiUrl}api/deals/free?page=${currentPage}&pageSize=${PAGE_SIZE}&brand=${dealBrandFilter}&cat=${dealCategoryFilter}&search=${searchQuery}`,
                {
                    headers: config.headers,
                }
            );
            setTotalPages(
                Math.ceil(response?.data?.data?.meta?.pagination?.total / PAGE_SIZE)
            );
            setProduct(response?.data?.data?.data);
            setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [currentPage,
        PAGE_SIZE,
        searchQuery,
        dealBrandFilter,
        dealCategoryFilter]
    );

    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}api/brand/getAll?page=1&pageSize=100&search=null`,
                {
                    headers: config.headers,
                }
            );
            setBrands(response?.data?.data?.data);
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    }, []);

    useEffect(() => {
        fetchBrands();
    }, [fetchBrands]);

    useImperativeHandle(
        ref,
        () => ({
            fetchData: fetchData,
        }),
        [fetchData]
    );

    useEffect(() => {
        fetchData();
    }, [
        currentPage,
        PAGE_SIZE,
        searchQuery,
        fetchData,
    ]);

    const handleFilterChange = (value, setFilter, setSelectedFilter) => {
        setFilter(value);
        setSelectedFilter(value);
    };

    const handleSearchChange = (deal) => {
        setSearchQuery(deal.target.value);
    };

    const handlePaginationClick = (type) => {
        setCurrentPage((prevPage) =>
            type === "prev" ? prevPage - 1 : prevPage + 1
        );
    };

    return (
        <div className="mt-2 deal-subpage">
            <div className="flex justify-between gap-2">
                <SearchInput
                    placeholder="Search discount"
                    {...props}
                    onChange={handleSearchChange}
                    className="mb-4"
                />
                <FilterIcon
                    condition={selectedBrandType || category}
                    onClick={() => setOpen(true)}
                />
            </div>
            <div className="flex items-center justify-between mb-4">
                <ShowingText
                    product={product}
                    filteredProduct={filteredProduct}
                    name={'discount'}
                />
                <div className="hidden md:flex">
                    <DealFilter
                        selectedBrandType={selectedBrandType}
                        setSelectedBrandType={setSelectedBrandType}
                        category={category}
                        setCategory={setCategory}
                        brands={brands}
                        handleFilterChange={handleFilterChange}
                        resetFilters={resetFilters}
                        setDealBrandFilter={setDealBrandFilter}
                        setDealCategoryFilter={setDealCategoryFilter}
                    />
                </div>
            </div>

            {loading ? (
                <Spin className="loader" tip="Loading..." />
            ) : product && product?.length > 0 ? (
                <Deal
                    product={product}
                    fetchData={fetchData}
                    setLoading={setLoading}
                />
            ) : (
                <SearchEmpty filteredProduct={filteredProduct}/>
            )}

            {product && product?.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePaginationClick={handlePaginationClick}
                    product={product}
                    filteredProduct={filteredProduct}
                    showingName={"discount"}
                />
            )}
            <DealDrawer
                selectedBrandType={selectedBrandType}
                setSelectedBrandType={setSelectedBrandType}
                category={category}
                setCategory={setCategory}
                brands={brands}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                setDealBrandFilter={setDealBrandFilter}
                setDealCategoryFilter={setDealCategoryFilter}
                open={open}
                setOpen={setOpen}
            />
        </div >
    );
}

export default Free;