import { Tabs } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ManageUserBuyer from "./manageBuyer";
import ManageUserPartner from "./managePartner";
import ManageUserAdmin from "./manageAdmin";
import "./index.scss";
import { Helmet } from "react-helmet";

function Index() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(() => {
    const storedTab = localStorage.getItem("manageUserActiveTab");
    return storedTab || "1";
  });

  const items = useMemo(
    () => [
      {
        key: "1",
        label: "Buyers",
        children: <ManageUserBuyer />,
      },
      {
        key: "2",
        label: "Partners",
        children: <ManageUserPartner />,
      },
      {
        key: "3",
        label: "SaaS22 Admins",
        children: <ManageUserAdmin />,
      },
    ],
    []
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabKey = searchParams.get("tab");
    if (tabKey && items.some((item) => item.key === tabKey)) {
      setActiveKey(tabKey);
      localStorage.setItem("manageUserActiveTab", tabKey);
    }
  }, [location.search, items]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    let route;
    navigate(route);
    localStorage.setItem("manageUserActiveTab", key);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SaaS22 Users</title>
      </Helmet>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={handleTabChange}
        className="primary-tab"
      >
        {items.map((item) => (
          <Tabs.TabPane key={item.key} tab={item.label}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default Index;
