import { Tabs } from 'antd';
import React, {
    useState,
    useEffect,
    useMemo
} from 'react';
import Stories from './story';
import { useNavigate, useLocation } from 'react-router-dom';
import "./index.scss";
import Webfeeed from './webfeed';
import SignInPopup from '../../components/Modal/signIn';

function Resources() {
    const [showSignInPopup, setShowSignInPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [activeKey, setActiveKey] = useState(() => {
        const storedTab = localStorage.getItem('resourceActiveTab');
        return storedTab || '2';
    });

    const items = useMemo(() => [
        {
            key: '2',
            label: 'Stories',
            children: <Stories />,
        },
        // {
        //     key: '3',
        //     label: 'Guides',
        //     children: <Guide />
        // },
        {
            key: '1',
            label: 'Web feed',
            children: <Webfeeed />
        },
    ], []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabKey = searchParams.get('tab');
        if (tabKey && items.some(item => item.key === tabKey)) {
            setActiveKey(tabKey);
            localStorage.setItem('resourceActiveTab', tabKey);
        }
    }, [location.search, items]);

    const handleTabChange = (key) => {
        setActiveKey(key);

        let route;
        switch (key) {
            case '1':
            case '2':
            case '3':
                route = '/resources';
                break;
            default:
                route = '/resources';
        }

        navigate(route);
        localStorage.setItem('resourceActiveTab', key);
    };

    return (
        <div>
            <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={handleTabChange}
                className='primary-tab parent-tab'
            >
                {items?.map(item => (
                    <Tabs.TabPane key={item.key} tab={item.label}>
                        {item.children}
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <SignInPopup visible={showSignInPopup} onClose={() => setShowSignInPopup(false)} />
        </div>
    );
}

export default Resources;
