import axios from "axios";
import config from "../../confiq/confiq";

export const fetchAllData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/home/getAll?page=${page}&pageSize=${PAGE_SIZE}`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchprojectData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/projects/getAll?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchWebfeedtData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/webFeed/getAll?page=${page}&pageSize=${PAGE_SIZE}&startDate=null&endDate=null&fun=null&cat=null&search=null&brand=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchStoryData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/story/getAll?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchGuideData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/guides/getAll?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchAllFavData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/bookmark/home`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};


export const fetchFavprojectData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/bookmark/project?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchFavWebfeedtData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/bookmark/webfeed?page=1&pageSize=10&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchFavStoryData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/bookmark/story?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};

export const fetchFavGuideData = (page, { PAGE_SIZE, setData }) => {
    axios.get(`${config.apiUrl}api/bookmark/guide?page=${page}&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`, {
        headers: config.headers,
    })
        .then(response => {
            setData(response?.data?.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};