import { ChevronLeftOutline } from 'heroicons-react';
import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchSyncDetails,
    syncHubspotData
} from './apiService';
import { Button, Spin } from 'antd';
import { LastdateText } from '.';
import {
    ViewPartnership1,
    ViewPartnership2,
    ViewPartnership3
} from '../../assets/img';
import { useUserRole } from '../../context/userContext';

function VendorDetail() {
    const navigate = useNavigate();
    const [loadingSync, setLoadingSync] = useState(false);
    const [loading, setLoading] = useState(false);
    const [syncText, setSyncText] = useState('Sync');
    const [syncDetails, setSyncDetails] = useState(null);
    const { id } = useParams();
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');

    useEffect(() => {
        getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
    }, [id, localStorageLabel, userRole]);

    const handleSyncClick = async () => {
        setLoadingSync(true);
        setSyncText('Syncing...');
        try {
            await syncHubspotData(id);
            await getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
            setSyncText('Sync');
        } catch (error) {
            setSyncText('Sync');
        } finally {
            setLoadingSync(false);
        }
    };

    return (
        <>
            {loading ?
                <Spin className='loader' tip='Loading...' />
                :
                <>
                    <div className='flex items-center justify-between gap-2 mb-5'>
                        <div className='flex flex-row gap-2'>
                            <div className='relative top-[5px] cursor-pointer' onClick={() => navigate(-1)}>
                                <ChevronLeftOutline />
                            </div>
                            <div>
                                <div className='flex flex-row gap-3 items-center'>
                                    <div className='text-black font-bold text-lg'>{syncDetails?.vendor}</div>
                                    {userRole === "Partner" &&
                                        <div
                                            className='text-sm purple-saas22 font-semibold cursor-pointer flex gap-1 items-center'
                                            onClick={handleSyncClick}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className={`size-4 ${loadingSync ? 'animate-spin' : ''}`}
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                                />
                                            </svg>
                                            {syncText}
                                        </div>
                                    }
                                </div>
                                <div className='text-sm Grayish'>
                                    <LastdateText syncDetails={syncDetails} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <VendorCTACard navigate={navigate} id={id} />
                    </div>
                </>
            }
        </>
    );
}

export default VendorDetail;


const VendorCTACard = ({ navigate, id }) => {
    return (
        <div className='parent-cta-vendor flex justify-center items-center bg-white'>
            <div className='flex gap-8 justify-center '>
                <div className='cta-vendor'>
                    <img src={ViewPartnership3} alt='' className='h-[120px] w-auto m-auto' />
                    <Button type="primary" onClick={() => navigate(`/partnerships/vendorDetail/${id}/viewDeals`)}>
                        View Deals
                    </Button>
                </div>
                <div className='cta-vendor'>
                    <img src={ViewPartnership2} alt='' className='h-[120px] w-auto m-auto' />
                    <Button type="primary" onClick={() => navigate(`/partnerships/vendorDetail/${id}/ViewContact`)}>
                        View Contacts
                    </Button>
                </div>
                <div className='cta-vendor'>
                    <img src={ViewPartnership1} alt='' className='h-[120px] w-auto m-auto' />
                    <Button type="primary" onClick={() => navigate(`/partnerships/vendorDetail/${id}/leadRegister`)}>
                        Registered Leads
                    </Button>
                </div>
            </div>
        </div>
    );
}


export const getSyncDetails = async (id, setSyncDetails, setLoading, userRole, localStorageLabel) => {
    try {
        setLoading(true);
        const data = await fetchSyncDetails(id, userRole, localStorageLabel);
        setSyncDetails(data?.data);
    } catch (err) {
        console.error("Error fetching sync details:", err);
    } finally {
        setLoading(false);
    }
};