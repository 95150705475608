import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    Button,
    Tabs,
} from 'antd';
import MyRequest from './myRequest';
import { NewRequestDrawer } from './newRequestDrawer';
import { useUserRole } from '../../context/userContext';
import { useAuth } from '../../context';
import { Helmet } from 'react-helmet';


const Projects = () => {
    const [strapiUrl, setStrapiUrl] = useState('');
    const userRole = useUserRole();
    const { userToken } = useAuth();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const savedActiveKey = localStorage.getItem('projectActiveTabKey');
    const [activeKey, setActiveKey] = useState(savedActiveKey || '4');

    const allProjectRef = useRef(null);
    const PopularRef = useRef(null);
    const savedRef = useRef(null);
    const myRequestRef = useRef(null);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error('REACT_APP_STRAPI_URL is not defined in the environment.');
        }
    }, []);

    const items = [
        // {
        //     key: '1',
        //     label: 'All ',
        //     children: <AllProjects ref={allProjectRef} />,
        // },
        // {
        //     key: '2',
        //     label: 'Popular',
        //     children: <Popular ref={PopularRef} />,
        // },
        ...(userRole !== 'Admin' && userRole !== 'Partner' && userToken
            ? [
                // {
                //     key: '3',
                //     label: 'Saved',
                //     children: <Saved ref={savedRef} />,
                // },
                {
                    key: '4',
                    label: 'My Requests',
                    children: <MyRequest ref={myRequestRef} />,
                },
            ]
            : []),
    ];

    const handleCreateProject = () => {
        window.open(`${strapiUrl}admin/content-manager/collectionType/api::project.project/create`);
    }

    const handleNewRequest = () => {
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
    };

    const handleTabChange = (key) => {
        localStorage.setItem('projectActiveTabKey', key);
        setActiveKey(key);
        allProjectRef.current && allProjectRef.current.fetchData();
        PopularRef.current && PopularRef.current.fetchData();
        savedRef.current && savedRef.current.fetchData();
        myRequestRef.current && myRequestRef.current.fetchData();

    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SaaS22 Requests</title>
            </Helmet>            <div className='flex md:hidden justify-between gap-2 mb-3 items-center'>
                <div className='text-xl font-semibold'> Projects</div>
                <div>
                    {userRole === "Buyer" && (
                        <Button type="primary"
                            onClick={handleNewRequest}
                        >
                            New Request
                        </Button>
                    )}
                </div>
            </div>
            <Tabs
                activeKey={activeKey}
                defaultActiveKey="1"
                items={items}
                className='primary-tab'
                tabBarExtraContent={
                    <div className='hidden md:block'>
                        {userRole === "Admin" && (
                            <Button type="primary" onClick={handleCreateProject}>
                                Create New Project
                            </Button>
                        )}

                        {userRole === "Buyer" && (
                            <Button type="primary"
                                onClick={handleNewRequest}
                            >
                                New Request
                            </Button>
                        )}
                    </div>
                }
                onChange={handleTabChange}

            />
            <NewRequestDrawer
                visible={drawerVisible}
                onClose={handleDrawerClose}
            />
        </>
    )
}
export default Projects;