import { Link } from "react-router-dom";
import { CalendarOutline } from "heroicons-react";
import moment from "moment";
import { Divider } from "antd";

const UpcomingEvents = (eventData) => {
  return (
    <div
      className={`${eventData.className} upcomingEvents projects-stats mb-5`}
    >
      <div className="flex flex-row justify-between">
        <span className="whitespace-nowrap text-lg md:text-xl font-bold mb-2 md:mb-3">
          Upcoming Events!
        </span>
        <Link to={"/events"} className="purple-saas22 font-medium">
          View all
        </Link>
      </div>
      {eventData?.eventData?.map((item, index) => {
        return (
          <>
            <div className="flex w-full gap-2 ">
              <CalendarOutline size={18} className="text-[#55AF68] w-6" />
              <div className="w-11/12">
                <p className="flex gap-2 text-sm Grayish items-center mb-3">
                  {moment(item.attributes.time).format("DD MMM YYYY")} •{" "}
                  {moment(item.attributes.time).format("HH:mm")}
                </p>

                <p className="toolName text-sm mb-2 md:mb-1 font-medium">
                  {item.attributes.event_Name}
                </p>
                <p className="text-sm mb-2 md:mb-3 content-threeline content-oneline capitalize">
                {item?.attributes?.country} {item?.attributes?.located && ","} {item?.attributes?.located}
                </p>
              </div>
            </div>
            {index !== eventData?.eventData?.length - 1 && (
              <Divider className="mt-0 mb-3" />
            )}
          </>
        );
      })}
    </div>
  );
};

export default UpcomingEvents;
