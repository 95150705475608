import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import SearchInput from "../../components/SearchInput";
import axios from "axios";
import SearchEmpty from "../../components/searchEmpty";
import config from "../../confiq/confiq";
import {
    Button,
    Spin
} from "antd";
import { Link } from "react-router-dom";
import { Deal } from "../../components/Card/deal";
import { useUserRole } from "../../context/userContext";
import FilterIcon from "../../components/filterIcon";
import DealDrawer, { DealFilter } from "./dealDrawer";
import "./index.scss";
import { Helmet } from "react-helmet";

function Deals({ props, ref }) {
    const [selectedBrandType, setSelectedBrandType] = useState("");
    const [category, setCategory] = useState("");
    const [loadingMostRead, setLoadingMostRead] = useState(false);
    const [loadingEndingSoon, setLoadingEndingSoon] = useState(false);
    const [loadingFree, setLoadingFree] = useState(false);
    const [productMostRead, setProductMostRead] = useState(null);
    const [productEndingSoon, setProductEndingSoon] = useState(null);
    const [productFree, setProductFree] = useState(null);
    const [totalMost, setTotalMost] = useState(null);
    const [totalEnding, setTotalEnding] = useState(null);
    const [totalFree, setTotalfree] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [dealBrandFilter, setDealBrandFilter] = useState(null);
    const [dealCategoryFilter, setDealCategoryFilter] = useState(null);
    const [brands, setBrands] = useState([]);
    const [strapiUrl, setStrapiUrl] = useState('');
    const userRole = useUserRole();
    const [open, setOpen] = useState(false);

    const PAGE_SIZE = 15;

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error('REACT_APP_STRAPI_URL is not defined in the environment.');
        }
    }, []);

    const fetchData = useCallback(async (fetchFunction, setLoading, setProduct, setTotal) => {
        try {
            setLoading(true);
            const response = await fetchFunction();
            const firstThreeItems = response?.data?.data?.data?.slice(0, 3);
            const totalCount = response?.data?.data?.meta?.pagination?.total;
            setProduct(firstThreeItems);
            setTotal(totalCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const mostReadFetchData = useCallback(() => {
        return axios.post(
            `${config.apiUrl}api/deals/mostread?page=1&pageSize=${PAGE_SIZE}&brand=${dealBrandFilter}&cat=${dealCategoryFilter}&search=${searchQuery}`, {},
            { headers: config.headers }
        );
    }, [PAGE_SIZE, searchQuery, dealBrandFilter, dealCategoryFilter]);

    const endingSoonFetchData = useCallback(() => {
        return axios.get(
            `${config.apiUrl}api/deals/endingsoon?page=1&pageSize=${PAGE_SIZE}&brand=${dealBrandFilter}&cat=${dealCategoryFilter}&search=${searchQuery}`,
            { headers: config.headers }
        );
    }, [PAGE_SIZE, searchQuery, dealBrandFilter, dealCategoryFilter]);

    const freeFetchData = useCallback(() => {
        return axios.get(
            `${config.apiUrl}api/deals/free?page=1&pageSize=${PAGE_SIZE}&brand=${dealBrandFilter}&cat=${dealCategoryFilter}&search=${searchQuery}`,
            { headers: config.headers }
        );
    }, [PAGE_SIZE, searchQuery, dealBrandFilter, dealCategoryFilter]);

    const resetFilters = () => {
        setSelectedBrandType(null);
        setCategory(null);
        setDealCategoryFilter(null);
        setDealBrandFilter(null);
        mostReadFetchData();
        endingSoonFetchData();
        freeFetchData();
    };

    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}api/brand/getAll?page=1&pageSize=100&search=null`,
                { headers: config.headers }
            );
            setBrands(response?.data?.data?.data);
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    }, []);

    useEffect(() => {
        fetchBrands();
    }, [fetchBrands]);

    useImperativeHandle(
        ref,
        () => ({
            fetchData: resetFilters,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        fetchData(mostReadFetchData, setLoadingMostRead, setProductMostRead, setTotalMost);
        fetchData(endingSoonFetchData, setLoadingEndingSoon, setProductEndingSoon, setTotalEnding);
        fetchData(freeFetchData, setLoadingFree, setProductFree, setTotalfree);
    }, [mostReadFetchData, endingSoonFetchData, freeFetchData, fetchData]);

    const handleFilterChange = (value, setFilter, setSelectedFilter) => {
        setFilter(value);
        setSelectedFilter(value);
    };

    const handleSearchChange = (deal) => {
        setSearchQuery(deal.target.value);
    };
    const handleCreateStory = () => {
        window.open(`${strapiUrl}admin/content-manager/collectionType/api::deal.deal/create`);
    };

    const totalSearchCount = totalMost + totalEnding + totalFree;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SaaS22 Discounts</title>
            </Helmet>
            <div className="mt-2">
                <div className='block md:hidden mb-3'>
                    <div className='text-xl font-semibold'> Discount</div>
                </div>
                {userRole === "Admin" &&
                    <div className="flex items-center justify-between mb-5">
                        <div className="text-xl font-bold text-black">Discount</div>
                        <div>
                            <Button type="primary" onClick={handleCreateStory}>
                                Create New Discount
                            </Button>

                        </div>
                    </div>
                }
                <div className="flex justify-between gap-2">
                    <SearchInput
                        placeholder="Search discount"
                        {...props}
                        onChange={handleSearchChange}
                        className="mb-4"
                    />
                    <FilterIcon
                        condition={selectedBrandType || category}
                        onClick={() => setOpen(true)}
                    />
                </div>
                <div className="hidden md:flex items-center justify-between mb-5">
                    <div></div>
                    <DealFilter
                        selectedBrandType={selectedBrandType}
                        setSelectedBrandType={setSelectedBrandType}
                        category={category}
                        setCategory={setCategory}
                        brands={brands}
                        handleFilterChange={handleFilterChange}
                        resetFilters={resetFilters}
                        setDealBrandFilter={setDealBrandFilter}
                        setDealCategoryFilter={setDealCategoryFilter}
                    />
                </div>

                {(!productMostRead || productMostRead.length === 0) &&
                    (!productEndingSoon || productEndingSoon.length === 0) &&
                    (!productFree || productFree.length === 0) && (
                        loadingMostRead ? <Spin className="loader" tip="Loading..." /> : <SearchEmpty filteredProduct={totalSearchCount} />
                    )}
                {productMostRead && productMostRead?.length > 0 &&
                    <div className="mb-5">
                        <div className="flex justify-between gap-2 items-center mb-4">
                            <h4 className="text-lg md:text-xl font-semibold md:font-bold">What’s hot</h4>
                            <Link to={"/discount/viewAll?tab=1"} className="purple-saas22 font-medium">
                                View all
                            </Link>
                        </div>
                        <div className="deals-container" >
                            <div className="deals-scroll" >
                                {loadingMostRead ? (
                                    <Spin className="loader" tip="Loading..." />
                                ) : productMostRead && productMostRead.length > 0 ? (
                                    <div className="deal-child ">
                                        <Deal
                                            product={productMostRead}
                                            fetchData={fetchData}
                                        />
                                    </div>
                                ) : (
                                    <SearchEmpty />
                                )}
                            </div>
                        </div>
                    </div>
                }
                {productEndingSoon && productEndingSoon?.length > 0 &&
                    <div className="mb-5">
                        <div className="flex justify-between gap-2 items-center mb-4">
                            <h4 className="text-lg md:text-xl font-semibold md:font-bold">Ending soon</h4>
                            <Link to={"/discount/viewAll?tab=2"} className="purple-saas22 font-medium">
                                View all
                            </Link>
                        </div>
                        <div className="deals-container" >
                            <div className="deals-scroll" >
                                {loadingEndingSoon ? (
                                    <Spin className="loader" tip="Loading..." />
                                ) : productEndingSoon && productEndingSoon.length > 0 ? (
                                    <div className="deal-child ">
                                        <Deal
                                            product={productEndingSoon}
                                            fetchData={fetchData}
                                        />
                                    </div>
                                ) : (
                                    <SearchEmpty />
                                )}
                            </div>
                        </div>
                    </div>
                }
                {productFree && productFree?.length > 0 &&
                    <div className="mb-5">
                        <div className="flex justify-between gap-2 items-center mb-4">
                            <h4 className="text-lg md:text-xl font-semibold md:font-bold">Freebies</h4>
                            <Link to={"/discount/viewAll?tab=3"} className="purple-saas22 font-medium">
                                View all
                            </Link>
                        </div>
                        <div className="deals-container" >
                            <div className="deals-scroll" >
                                {loadingFree ? (
                                    <Spin className="loader" tip="Loading..." />
                                ) : productFree && productFree.length > 0 ? (
                                    <div className="deal-child ">
                                        <Deal
                                            product={productFree}
                                            fetchData={fetchData}
                                        />
                                    </div>
                                ) : (
                                    <SearchEmpty />
                                )}
                            </div>
                        </div>
                    </div>
                }

                <DealDrawer
                    selectedBrandType={selectedBrandType}
                    setSelectedBrandType={setSelectedBrandType}
                    category={category}
                    setCategory={setCategory}
                    brands={brands}
                    handleFilterChange={handleFilterChange}
                    resetFilters={resetFilters}
                    setDealBrandFilter={setDealBrandFilter}
                    setDealCategoryFilter={setDealCategoryFilter}
                    open={open}
                    setOpen={setOpen}
                />
            </div>
        </>
    );
}

export default Deals;