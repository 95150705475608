import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import SearchInput from "../../components/SearchInput";
import axios from "axios";
import SearchEmpty from "../../components/searchEmpty";
import config from "../../confiq/confiq";
import {
    Button,
    DatePicker,
    Drawer,
    Select,
    Spin
} from "antd";
import { Event } from "../../components/Card/event";
import { dateFormat } from "../../utils";
import "./index.scss";
import FilterIcon, { ShowingText } from "../../components/filterIcon";
import { ChevronDownOutline } from "heroicons-react";
import { StaticContent } from ".";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const All = forwardRef((props, ref) => {

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedeventType, setSelectedEventType] = useState(null);
    const [selectedBrandType, setSelectedBrandType] = useState(null);
    const [category, setCategory] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([]);

    const [product, setProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [eventTypeFilter, setEventTypeFilter] = useState(null);
    const [eventLocationFilter, setEventLocationFilter] = useState(null);
    const [eventBrandFilter, setEventBrandFilter] = useState(null);
    const [eventCategoryFilter, setEventCategoryFilter] = useState(null);

    const [currentPage,] = useState(1);
    const [, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredProduct, setFilteredProduct] = useState();
    const [locations, setLocations] = useState([]);
    const [brands, setBrands] = useState([]);
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(15);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchDataLocation = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}api/country/all`, {
                headers: config.headers,
            });
            setCountry(response?.data);
        } catch {
            // console.log(error)
        }
    };

    useEffect(() => {
        fetchDataLocation();
    }, []);

    const resetFilters = () => {
        setSelectedEventType(null);
        setSelectedBrandType(null);
        setCategory(null);
        setEventTypeFilter(null);
        setEventLocationFilter(null);
        setEventCategoryFilter(null);
        setEventBrandFilter(null);
        setSelectedLocation(null);
        setSelectedDateRange([]);
        fetchData();
    };

    const fetchData = useCallback(async () => {
        const startDate = selectedDateRange[0]?.toISOString() || null;
        const endDate = selectedDateRange[1]?.toISOString() || null;

        try {
            if (itemsToShow === 15) {
                setLoading(true);
            }
            setIsSubmitting(true);
            const response = await axios.get(
                `${config.apiUrl}api/events/getAll?page=${currentPage}&pageSize=${itemsToShow}&startDate=${startDate}&endDate=${endDate}&price=${eventTypeFilter}&brand=${eventBrandFilter}&location=${eventLocationFilter}&cat=${eventCategoryFilter}&search=${searchQuery}`,
                {
                    headers: config.headers,
                }
            );
            setTotalPages(
                Math.ceil(response?.data?.data?.meta?.pagination?.total / itemsToShow)
            );
            setProduct(response?.data?.data?.data);
            setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            if (itemsToShow === 15) {
                setLoading(false);
            }
            setIsSubmitting(false);
        }
    }, [currentPage,
        searchQuery,
        eventTypeFilter,
        eventLocationFilter,
        selectedDateRange,
        eventBrandFilter,
        eventCategoryFilter,
        itemsToShow
    ]
    );

    const fetchLocations = useCallback(async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}api/events/locations`,
                {
                    headers: config.headers,
                }
            );
            setLocations(response?.data?.data);
        } catch (error) {
            console.error("Error fetching locations:", error);
        }
    }, []);

    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}api/brand/getAll?page=1&pageSize=100&search=null`,
                {
                    headers: config.headers,
                }
            );
            setBrands(response?.data?.data?.data);
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    }, []);

    useEffect(() => {
        fetchLocations();
        fetchBrands();
    }, [fetchLocations, fetchBrands]);

    useImperativeHandle(
        ref,
        () => ({
            fetchData: fetchData,
        }),
        [fetchData]
    );

    useEffect(() => {
        fetchData();
    }, [
        currentPage,
        itemsToShow,
        eventTypeFilter,
        eventLocationFilter,
        searchQuery,
        fetchData,
    ]);

    const handleFilterChange = (value, setFilter, setSelectedFilter) => {
        setFilter(value);
        setSelectedFilter(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleLoadMore = () => {
        setItemsToShow(prevItems => prevItems + 15);
    };
    const handleDateRangeChange = (dates) => {
        const newDates = Array.isArray(dates) ? dates : [];
        setSelectedDateRange(newDates);
    };

    return (
        <div className="mt-2">
            <StaticContent />
            <div className="flex justify-between gap-2">
                <SearchInput
                    placeholder="Search events"
                    {...props}
                    onChange={handleSearchChange}
                    className="mb-4"
                />
                <FilterIcon
                    condition={(selectedeventType ||
                        selectedLocation ||
                        selectedBrandType ||
                        category ||
                        selectedDateRange.length > 0
                    )}
                    onClick={() => setOpen(true)}
                />
            </div>
            <div className="flex items-center justify-between mb-4">
                <ShowingText
                    product={product}
                    filteredProduct={filteredProduct}
                    name={'events'}
                />
                <div className="hidden md:flex">
                    <EventFilter
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        selectedeventType={selectedeventType}
                        setSelectedEventType={setSelectedEventType}
                        selectedBrandType={selectedBrandType}
                        setSelectedBrandType={setSelectedBrandType}
                        category={category}
                        setEventCategoryFilter={setEventCategoryFilter}
                        setEventLocationFilter={setEventLocationFilter}
                        setEventTypeFilter={setEventTypeFilter}
                        setEventBrandFilter={setEventBrandFilter}
                        selectedDateRange={selectedDateRange}
                        handleFilterChange={handleFilterChange}
                        handleDateRangeChange={handleDateRangeChange}
                        resetFilters={resetFilters}
                        product={product}
                        locations={country}
                        brands={brands}
                        setCategory={setCategory}
                    />
                </div>

            </div>

            {loading ? (
                <Spin className="loader" tip="Loading..." />
            ) : product && product?.length > 0 ? (
                <Event
                    product={product}
                    fetchData={fetchData}
                    setLoading={setLoading}
                />
            ) : (
                <SearchEmpty filteredProduct={filteredProduct} />
            )}
            {product && product?.length > 14 && filteredProduct !== product?.length && (
                <div className="flex items-center justify-center gap-1 text-[#5D5FF3] font-semibold" >
                    <span className="cursor-pointer flex items-center" onClick={handleLoadMore}>
                        {isSubmitting ? 'Loading' : 'Load More'} <ChevronDownOutline height={18} />
                    </span>
                </div>
            )}
            <Drawer
                title={"Filters"}
                placement={"bottom"}
                className="drawer-filter"
                height={450}
                open={open}
                onClose={() => setOpen(false)}
                footer={
                    <div className="flex md:hidden w-full">
                        <Button type="secondary"
                            className=" w-full"
                            onClick={() => {
                                resetFilters();
                                setOpen(false);
                            }}
                            disabled={!(selectedeventType ||
                                selectedLocation ||
                                selectedBrandType ||
                                category ||
                                selectedDateRange.length > 0
                            )}>
                            Clear Filters
                        </Button>
                    </div>
                }
            >
                <EventFilter
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    selectedeventType={selectedeventType}
                    setSelectedEventType={setSelectedEventType}
                    selectedBrandType={selectedBrandType}
                    setSelectedBrandType={setSelectedBrandType}
                    category={category}
                    setEventCategoryFilter={setEventCategoryFilter}
                    setEventLocationFilter={setEventLocationFilter}
                    setEventTypeFilter={setEventTypeFilter}
                    setEventBrandFilter={setEventBrandFilter}
                    selectedDateRange={selectedDateRange}
                    handleFilterChange={handleFilterChange}
                    handleDateRangeChange={handleDateRangeChange}
                    resetFilters={resetFilters}
                    product={product}
                    locations={locations}
                    brands={brands}
                    setCategory={setCategory}
                />
            </Drawer>
        </div >
    );
});

const EventFilter = ({
    selectedLocation,
    setSelectedLocation,
    selectedeventType,
    setSelectedEventType,
    selectedBrandType,
    setSelectedBrandType,
    category,
    setEventCategoryFilter,
    setEventLocationFilter,
    setEventTypeFilter,
    setEventBrandFilter,
    selectedDateRange,
    handleFilterChange,
    handleDateRangeChange,
    resetFilters,
    product,
    locations,
    brands,
    setCategory,
}) => {
    return (
        <>
            <div className="grid md:flex flex-row items-center gap-4 md:gap-2 event-page">
                <div className="hidden md:flex">
                    {(selectedeventType ||
                        selectedLocation ||
                        selectedBrandType ||
                        category ||
                        selectedDateRange.length > 0) && (
                            <div
                                className="cursor-pointer purple-saas22"
                                onClick={resetFilters}
                            >
                                Clear Filters
                            </div>
                        )}
                </div>
                <Select
                    showSearch
                    value={category}
                    onChange={(value) =>
                        handleFilterChange(value, setEventCategoryFilter, setCategory)
                    }
                    className="custom-select"
                    placeholder={"Category"}
                    style={{ width: 120 }}
                >
                    <Option value="" disabled>
                        Category
                    </Option>
                    <Option value="eCommerce">eCommerce</Option>
                    <Option value="Consulting">Consulting</Option>
                    <Option value="SaaS">SaaS</Option>
                </Select>

                <Select
                    showSearch
                    value={selectedLocation}
                    onChange={(value) =>
                        handleFilterChange(value, setEventLocationFilter, setSelectedLocation)
                    }
                    placeholder={"Location"}
                    className="custom-select"
                    style={{ width: 120 }}
                >
                    <Option value="" disabled>
                        Location
                    </Option>
                    {locations &&
                        locations?.map((item) => (
                            <Option
                                key={item?.id}
                                value={item?.countryName}
                                className="capitalize"
                            >
                                {item?.countryName}
                            </Option>
                        ))}
                </Select>
                <RangePicker
                    value={selectedDateRange}
                    format={dateFormat}
                    className="range-picker custom-select"
                    onChange={handleDateRangeChange}
                    allowClear={selectedDateRange?.length > 0}
                />

                <Select
                    value={selectedeventType}
                    onChange={(value) =>
                        handleFilterChange(value, setEventTypeFilter, setSelectedEventType)
                    }
                    placeholder={"Price"}
                    className="custom-select"
                    style={{ width: 120 }}
                >
                    <Option value="" disabled>
                        Price
                    </Option>
                    <Option value="free">Free</Option>
                    <Option value="paid">Paid</Option>
                </Select>

                <Select
                    value={selectedBrandType}
                    onChange={(value) =>
                        handleFilterChange(value, setEventBrandFilter, setSelectedBrandType)
                    }
                    placeholder={"Company"}
                    className="custom-select"
                    style={{ width: 120 }}
                >
                    <Option value="" disabled>
                        Company
                    </Option>
                    {brands &&
                        brands?.map((item) => (
                            <Option
                                key={item?.id}
                                value={item?.attributes?.vendor_Name}
                                className="capitalize"
                            >
                                {item?.attributes?.vendor_Name}
                            </Option>
                        ))}
                </Select>
            </div>
        </>
    );
};
