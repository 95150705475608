import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../confiq/confiq";
import { Drawer, Tag, Spin, Button } from "antd";
import StatusTag from "../../components/statusTag";
import { LabelText } from "../../utils";
import { SignInIcon } from "../../assets/img";

export const UserDetailsDrawer = ({
  userId,
  onClose,
  userRole,
  handleRevokeAccess,
}) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${config.apiUrl}api/admin/userdetails/${userId}`, {
        headers: config.headers,
      })
      .then((response) => {
        setUserData(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
        setLoading(false);
      });
  }, [userId]);
  return (
    <Drawer
      title={false}
      placement="right"
      onClose={onClose}
      className="drawer-bottom-none pb-none"
      visible={true}
      width={420}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        {userData && (
          <>
            <div className="mb-8 text-center">
              <div className="profile-img flex justify-center mb-2 items-center">
                <img
                  src={userData?.image?.path || SignInIcon}
                  alt="profile"
                  className="mb-2"
                />
              </div>
              <div className="midnight-blue font-semibold flex gap-3 items-center justify-center mb-1">
                <span className="capitalize">
                  {userData?.first_name} {userData?.last_name}{" "}
                </span>
                <span>
                  {userRole === "Partner" ? (
                    <Tag color="blue">Partner</Tag>
                  ) : userRole === "Admin" ? (
                    <Tag className="admin-tag">Admin</Tag>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {userRole !== "Admin" && (
                <div className="Grayish capitalize ">
                  {userData?.title || "N/A"}
                </div>
              )}
            </div>
            {userRole === "Buyer" && (
              <LabelText
                label={"Company Name"}
                text={userData?.company || "N/A"}
              />
            )}
            <LabelText
              label={"Email"}
              text={<div className="lowercase">{userData?.email || "N/A"}</div>}
            />
            {userRole !== "Admin" && (
              <>
                <LabelText label={"Phone Number"} text={userData?.phone || "N/A"} />
                <LabelText label={"Country / Timezone"} text={"India"} />
                <LabelText
                  label={"LinkedIn URL"}
                  text={
                    userData?.social_links?.linkedin ? (
                      <a
                        href={
                          userData.social_links.linkedin.startsWith("https")
                            ? userData.social_links.linkedin
                            : `https://${userData.social_links.linkedin}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="purple-saas22"
                      >
                        View Profile
                      </a>
                    ) : (
                      "N/A"
                    )
                  }
                />
                {userRole === "Partner" && (
                  <>
                    <LabelText
                      label={"Company Name"}
                      text={userData?.partner_details?.company || "N/A"}
                    />
                    <LabelText
                      label={"Website"}
                      text={
                        userData?.partner_details?.website ? (
                          <a
                            href={
                              userData.partner_details.website.startsWith(
                                "https"
                              )
                                ? userData.partner_details.website
                                : `https://${userData.partner_details.website}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="purple-saas22"
                          >
                            Click here
                          </a>
                        ) : (
                          "N/A"
                        )
                      }
                    />
                    <LabelText
                      label={"Services Offered"}
                      text={userData?.partner_details?.service || "N/A"}
                    />
                    <LabelText
                      label={"Additional Information"}
                      text={userData?.partner_details?.description || "N/A"}
                    />
                    <LabelText
                      label={"Consent for receiving newsletter"}
                      text={userData?.partner_details?.updates_subscription === true ? "yes" : userData?.partner_details?.updates_subscription === false ? "No" : "N/A"}
                    />
                    <LabelText
                      label={"Consent for getting featured in blog"}
                      text={userData?.partner_details?.blog_subscription === true ? "yes" : userData?.partner_details?.blog_subscription === false ? "No" : "N/A"}
                    />
                  </>
                )}
              </>
            )}
            {/* <LabelText
              label={"Joined On"}
              text={moment(userData?.created_at).format("DD-MMM-YYYY")}
            /> */}
            {userRole === "Admin" && (
              <LabelText
                label={"Invite Status"}
                text={<StatusTag
                  status={userData?.status && userData?.status.toLowerCase() === 'revoke' ? 'Revoked' :  userData?.status.toLowerCase() === 'approved' ? 'accepted' : userData?.status}
                />}
              />
            )}
            {userRole === "Buyer" && (
              <>
                <LabelText
                  label={"Tell us more about your company"}
                  text={userData?.about_company || "N/A"}
                />
                <LabelText
                  label={"How did you hear about us?"}
                  text={userData?.reference || "N/A"}
                />
              </>
            )}
            {userRole === "Partner" && (
              <>
                {userData?.status !== "pending" ? (
                  <LabelText
                    label={"Status"}
                    text={<StatusTag status={userData?.status} />}
                  />
                ) : (
                  <div className="flex items-center justify-between gap-2 sticky inset-0 bottom-0 bg-white py-4">
                    <Button
                      type="secondary"
                      onClick={() => handleRevokeAccess(userData, "rejected")}
                    >
                      Reject
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleRevokeAccess(userData, "approved")}
                    >
                      Approve
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Spin>
    </Drawer>
  );
};
