import React, { useEffect, useState } from 'react';
import { getPartnerships } from './apiService';
import PartnershipCard from '../../components/Card/partnership';
import SearchInput from '../../components/SearchInput';
import { ShowingText } from '../../components/filterIcon';
import "./index.scss";
import Pagination from '../../components/paginationSet';
import { Button, Spin } from 'antd';
import { useUserRole } from '../../context/userContext';
import { Helmet } from 'react-helmet';

function Partnerships() {
    const [partnerships, setPartnerships] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [filteredProduct, setFilteredProduct] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(true);
    const userRole = useUserRole();
    const [strapiUrl, setStrapiUrl] = useState("");

    const PAGE_SIZE = 10;

    useEffect(() => {
        const fetchPartnerships = async () => {
            setLoading(true);
            try {
                const data = await getPartnerships(currentPage, PAGE_SIZE, searchQuery || null, userRole);
                setPartnerships(data?.data || []);
                setFilteredProduct(data?.meta?.pagination?.total);
                setTotalPages(
                    Math.ceil(data?.meta?.pagination?.total / PAGE_SIZE)
                );
            } catch (error) {
                console.error("Error fetching partnerships:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPartnerships();
    }, [searchQuery, currentPage, userRole]);


    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
        }
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePaginationClick = (type) => {
        setCurrentPage((prevPage) =>
            type === "prev" ? prevPage - 1 : prevPage + 1
        );
    };

    const handleCreateStory = () => {
        window.open(`${strapiUrl}admin/content-manager/collection-types/api::vendor.vendor?page=1&pageSize=10&sort=vendor_Name:ASC`);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SaaS22 Partnerships</title>
            </Helmet>
            <div className='flex items-center justify-between mb-4'>
                <h4 className='text-black font-semibold text-lg'>Partnerships with vendors</h4>

                {userRole === "Admin" ?
                    <Button type="primary" onClick={handleCreateStory}>
                        Manage Vendors
                    </Button>
                    :
                    <div className="Grayish">
                        To partner with a new vendor -&nbsp;
                        <a
                            href="mailto:contact@saas22.com"
                            className='purple-saas22'
                        >
                            Write to us
                        </a>
                    </div>
                }

            </div>
            <SearchInput
                placeholder="Search for vendor or describe what you are looking for.  ex: enterprise copilot"
                onChange={handleSearchChange}
                className="mb-4"
            />
            <div className='mb-4 text-sm'>
                <ShowingText
                    product={partnerships}
                    filteredProduct={filteredProduct}
                    name={'vendors'}
                    className={'text-sm'}
                />
            </div>
            {loading ? (
                <Spin className='loader' tip='Loading...' />
            ) : (
                <>
                    <PartnershipCard partnerships={partnerships} filteredProduct={filteredProduct} />
                    {partnerships && partnerships.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePaginationClick={handlePaginationClick}
                            product={partnerships}
                            filteredProduct={filteredProduct}
                            showingName={"vendors"}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default Partnerships;

export const LastdateText = ({ syncDetails }) => {
    const lastSyncedDate = new Date(syncDetails?.last_synced_at);
    const formattedDate = lastSyncedDate.toString() !== 'Invalid Date' ? lastSyncedDate.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }) : 'N/A';

    const formattedTime = lastSyncedDate.toString() !== 'Invalid Date' ? lastSyncedDate.toLocaleTimeString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }) : 'N/A';
    return <> Last synced on {formattedDate}, {formattedTime}</>
}
