import { Button, Col, Dropdown, Form, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import axios from "axios";
import config from "../../confiq/confiq";
import {
  DotsVerticalOutline,
  PencilAltOutline,
  TrashOutline,
} from "heroicons-react";
import SearchEmpty from "../../components/searchEmpty";
import ConfirmationModal from "../../components/Modal/confirmationDelete";
import { Helmet } from "react-helmet";

function Content() {
  const [toolsData, setToolsData] = useState([]);
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState(null);
  const [strapiUrl, setStrapiUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}api/tools/getAll?page=1&pageSize=50&search=${searchQuery}`,
        {
          headers: config.headers,
        }
      );

      const tools = response?.data?.data;
      setToolsData(tools || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleOk = async (id) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/tools/remove/${id}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (response?.data?.statusCode === 201) {
        message.success("tool deleted successfully.");
        setIsOpenConfirmationModal(false);
        await fetchData();
      } else {
        message.error(
          "Oops! Something went wrong. Story could not be deleted. Please try again."
        );
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  const handleEditClick = async (id) => {
    window.open(
      `${strapiUrl}admin/content-manager/collectionType/api::tool.tool/${id}`
    );
  };

  const handleAddClick = async (id) => {
    window.open(
      `${strapiUrl}admin/content-manager/collectionType/api::tool.tool/create`
    );
  };

  const toolDataMap = [
    ...new Set(toolsData?.map((item) => item?.attributes?.category)),
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SaaS22 Content</title>
      </Helmet>
      {loading ? (
        <Spin className="loader" tip="Loading..." />
      ) : (
        <>
          <div className="flex justify-between mb-5 items-center">
            <div>
              <h4 className="text-xl font-semibold text-black">Tools</h4>
            </div>
            <div>
              <Button type="primary" onClick={handleAddClick}>
                Add Tool
              </Button>
            </div>
          </div>
          <Form name="step2" layout="vertical" form={form} className="pb-24">
            <Row gutter={16}>
              <Col span={24} className="mb-4">
                <SearchInput
                  placeholder="Search by tools"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <div className="flex flex-col gap-4">
                  {toolDataMap?.length === 0 ? (
                    <SearchEmpty />
                  ) : (
                    toolDataMap &&
                    toolDataMap?.map((category, index) => (
                      <div key={index} className="tools-section mb-3">
                        <h6 className="font-medium text-lg text-black mb-2">
                          {category}
                        </h6>
                        <Row gutter={[16, 16]}>
                          {toolsData &&
                            toolsData
                              ?.filter(
                                (item) =>
                                  item?.attributes?.category === category
                              )
                              ?.map((tool) => {
                                return (
                                  <Col md={6} key={tool?.id}>
                                    <div className="pl-2 tool-item flex gap-2 flex-row items-center justify-between ">
                                      <div className="flex flex-row gap-3 items-center w-4/5">
                                        <img
                                          src={
                                            tool?.attributes?.image?.data
                                              ?.attributes?.url
                                          }
                                          alt={tool?.name}
                                          className="logo-image"
                                        />
                                        <div className="toolName w-11/12">
                                          {" "}
                                          {tool?.attributes?.name}
                                        </div>
                                      </div>
                                      <div className="text-end">
                                        <div className="w-8 flex justify-end">
                                          <Dropdown
                                            menu={{
                                              items: [
                                                {
                                                  key: "1",
                                                  icon: (
                                                    <PencilAltOutline
                                                      color="#1F2937"
                                                      size={16}
                                                    />
                                                  ),
                                                  label: "Edit",
                                                  onClick: () =>
                                                    handleEditClick(tool.id),
                                                },
                                                {
                                                  key: "2",
                                                  icon: (
                                                    <TrashOutline
                                                      color="#1F2937"
                                                      size={16}
                                                    />
                                                  ),
                                                  label: "Delete",
                                                  onClick: () => {
                                                    setIsOpenConfirmationModal(
                                                      true
                                                    );
                                                    setSelectedId(tool.id);
                                                  },
                                                },
                                              ],
                                            }}
                                            placement="bottomRight"
                                          >
                                            <div className="cursor-pointer">
                                              <span className="ant-dropdown-link">
                                                <DotsVerticalOutline
                                                  color="#AEAEAE"
                                                  width={18}
                                                />
                                              </span>
                                            </div>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                        </Row>
                      </div>
                    ))
                  )}
                </div>
              </Col>
            </Row>
          </Form>
          {isOpenConfirmationModal && (
            <ConfirmationModal
              handleOk={handleOk}
              handleCancel={handleCancel}
              selectedProjectId={selectedId}
              open={isOpenConfirmationModal}
              modalDesc="Are you sure want to delete this tool?"
            />
          )}
        </>
      )}
    </>
  );
}

export default Content;
