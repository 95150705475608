import moment from 'moment';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import TableAntd from '../../../components/table';
import {
    useCallback,
    useEffect,
    useState
} from 'react';
import { dateFormat } from '../../../utils';
import {
    DatePicker,
    Select
} from 'antd';
import SearchInput from '../../../components/SearchInput';
import {
    fetchLeadQualOptions,
    fetchOriginOptions,
    fetchContacts
} from '../apiService';
import SearchEmpty from '../../../components/searchEmpty';
import { useUserRole } from '../../../context/userContext';
import { getSyncDetails } from '../vendorDetail';

const { RangePicker } = DatePicker;
const { Option } = Select;

function TableContact() {
    const [requestData, setRequestData] = useState();
    const [requestDataTotal, setRequestDataTotal] = useState();
    const [, setLoading] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [leadQualOptions, setLeadQualOptions] = useState([]);
    const [originOptions, setOriginOptions] = useState([]);
    const [selectedLeadQual, setSelectedLeadQual] = useState(null);
    const [, setSyncDetails] = useState(null);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');

    useEffect(() => {
        getSyncDetails(id, setSyncDetails, setLoading, userRole, localStorageLabel);
    }, [id, localStorageLabel, userRole]);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const data = await fetchContacts(
                id,
                userRole,
                selectedOrigin,
                selectedLeadQual,
                selectedDateRange,
                searchQuery,
                localStorageLabel
            );

            setRequestData(data?.data);
            setRequestDataTotal(data?.totalCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [id, localStorageLabel, searchQuery, selectedDateRange, selectedLeadQual, selectedOrigin, userRole]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const [leadQualResponse, originResponse] = await Promise.all([
                    fetchLeadQualOptions(id),
                    fetchOriginOptions(id)
                ]);

                setLeadQualOptions(leadQualResponse?.data || []);
                setOriginOptions(originResponse?.data || []);
            } catch (error) {
                console.error("Error fetching filter options:", error);
            }
        };

        fetchFilters();
    }, [id]);

    const columns = [
        {
            title: "Full Name",
            dataIndex: "firstname",
            key: "fullname",
            render: (text, record) => {
                const firstName = record?.firstname ? record?.firstname : "";
                const lastName = record?.lastname ? record?.lastname : "";
                return `${firstName} ${lastName}`.trim();
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Company Name",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Phone number",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Lead QUALIFICATION",
            dataIndex: "lead_qualification",
            key: "lead_qualification",
        },
        {
            title: "Created At",
            dataIndex: "createdate",
            key: "createdate",
            render: (text, record) => {
                return moment(record?.createdate, 'x').format("DD MMM YYYY");
            },
        },
    ];


    const handleDateRangeChange = (dates) => {
        const newDates = Array.isArray(dates) ? dates : [];
        setSelectedDateRange(newDates);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleLeadQualChange = (value) => {
        setSelectedLeadQual(value);
    };

    const handleOriginChange = (value) => {
        setSelectedOrigin(value);
    };

    const resetFilters = () => {
        setSelectedDateRange([]);
        setSearchQuery('');
        setSelectedLeadQual(null);
        setSelectedOrigin(null);
    };
    const handleRowClick = (record) => {
        navigate(`/partnerships/vendorDetail/${id}/ViewContact/${record.email}`);
    };

    return (
        <div className='table-cursor'>
            <div className='flex gap-2 justify-between  items-center mb-2 '>
                <div className='w-1/4'>
                    <SearchInput
                        placeholder="Search by company name and email"
                        onChange={handleSearchChange}
                        className="mb-4"
                    />
                </div>
                <div className='mb-4 flex flex-row gap-3 items-center event-page'>
                    <div className="hidden md:flex">
                        {(selectedLeadQual || selectedOrigin || selectedDateRange.length > 0) && (
                            <div className="cursor-pointer purple-saas22" onClick={resetFilters}>
                                Clear Filters
                            </div>
                        )}
                    </div>
                    <RangePicker
                        value={selectedDateRange}
                        format={dateFormat}
                        className="range-picker"
                        onChange={handleDateRangeChange}
                        allowClear={selectedDateRange.length > 0}
                    />
                    <Select
                        showSearch
                        placeholder="Lead Qualification"
                        optionFilterProp="children"
                        className="custom-select"
                        onChange={handleLeadQualChange}
                        value={selectedLeadQual}
                    >
                        <Option value="SQL">SQL</Option>
                        <Option value="MQL">MQL</Option>
                        <Option value="Subscriber">Subscriber</Option>
                        <Option value="Thank you page Sign up">Thank you page Sign up</Option>
                        <Option value="JUNK">JUNK</Option>
                        <Option value="Outbound">Outbound</Option>
                        <Option value="Repeat">Repeat</Option>
                        <Option value="Student Sign-ups">Student Sign-ups</Option>
                        <Option value="Gmail to Hubspot">Gmail to Hubspot</Option>
                        <Option value="Competitor">Competitor</Option>
                        <Option value="Chat">Chat</Option>
                    </Select>
                    <Select
                        showSearch
                        placeholder="Origin"
                        optionFilterProp="children"
                        className="custom-select"
                        onChange={handleOriginChange}
                        value={selectedOrigin}
                    >
                        <Option value="Shared by SurveySparrow">Shared by SurveySparrow</Option>
                        <Option value="Logged by Partner">Logged by Partner</Option>
                    </Select>
                </div>
            </div>

            <TableAntd
                dataSource={requestData}
                columns={columns}
                locale={{ emptyText: <SearchEmpty filteredProduct={requestDataTotal} /> }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
        </div >
    );
}

export default TableContact;