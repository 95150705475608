import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../context';
import { Button, Tabs } from 'antd';
import { AllWebfeed } from './all';
import { Favourites } from './fav';
import { useUserRole } from '../../../context/userContext';
import { Helmet } from 'react-helmet';

export default function Webfeeed() {
    const [strapiUrl, setStrapiUrl] = useState('');
    const { userToken } = useAuth();
    const userRole = useUserRole();
    const savedActiveKey = localStorage.getItem('webfeedActiveTabKey');
    const [activeKey, setActiveKey] = useState(savedActiveKey || '1');

    const allStoriesRef = useRef(null);
    const favouritesRef = useRef(null);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error('REACT_APP_STRAPI_URL is not defined in the environment.');
        }
    }, []);

    const items = [
        {
            key: '1',
            label: 'All ',
            children: <AllWebfeed ref={allStoriesRef} />,
        },
        ...(userRole !== 'Admin' && userToken
            ? [
                {
                    key: '2',
                    label: 'Favourites',
                    children: <Favourites ref={favouritesRef} />,
                },
            ]
            : []),
    ];

    const handleCreateStory = () => {
        window.open(`${strapiUrl}admin/content-manager/collectionType/api::web-feed.web-feed/create`);
    };

    const handleTabChange = (key) => {
        localStorage.setItem('webfeedActiveTabKey', key);
        setActiveKey(key);
        allStoriesRef.current && allStoriesRef.current.fetchData();
        favouritesRef.current && favouritesRef.current.fetchData();
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SaaS22 Webfeed: The best curated AI and SaaS content from the world over</title>
                <meta name="description" content="Stay ahead with SaaS22 Webfeed—your go-to source for the best curated AI and SaaS content, insights, and trends from industry leaders worldwide." />
            </Helmet>
            <div className='block md:hidden '>
                <div className='text-xl font-semibold'> Web Feed</div>
            </div>
            <Tabs
                activeKey={activeKey}
                defaultActiveKey="1"
                items={items}
                className="primary-tab child-tab"
                tabBarExtraContent={
                    userRole === 'Admin' && (
                        <Button type="primary" onClick={handleCreateStory}>
                            Create New Web feed
                        </Button>
                    )
                }
                onChange={handleTabChange}
            />
        </div>
    );
}
