import {
    Button,
    Drawer,
    Form,
    Input,
    Select
} from "antd";

const { Option } = Select;

export const RegisterNewLeadDrawer = ({
    visible,
    handleSubmit,
    onClose,
    isSubmitting,
    form
}) => {
    
    return (
        <Drawer
            title="Register New Lead"
            width={400}
            visible={visible}
            onClose={onClose}
            destroyOnClose
            maskClosable={false}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                disabled={isSubmitting}
            >
                <Form.Item
                    name="fullName"
                    label="Full Name"
                    rules={[
                        { required: true, message: "" },
                        { max: 30, message: 'Maximum 30 characters allowed' },
                    ]}
                >
                    <Input placeholder="Enter the full name" />
                </Form.Item>

                <Form.Item
                    name="potentialPlan"
                    label="Potential Plan"
                    rules={[{ required: true, message: "" }]}
                >
                    <Select placeholder="Select a plan">
                        <Option value="Starter">Starter</Option>
                        <Option value="Business">Business</Option>
                        <Option value="Professional">Professional</Option>
                        <Option value="Enterprise Starter">Enterprise Starter</Option>
                        <Option value="Enterprise Advanced">Enterprise Advanced</Option>
                        <Option value="VOC Starter">VOC Starter</Option>
                        <Option value="VOC Advanced">VOC Advanced</Option>
                        <Option value="Reputation - Starter">Reputation - Starter</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "" }]}
                >
                    <Input placeholder="Eg: abc@example.com" />
                </Form.Item>

                <Form.Item
                    name="companyName"
                    label="Company Name"
                    rules={[
                        { required: true, message: "" },
                        { max: 100, message: 'Maximum 100 characters allowed' },
                    ]}
                >
                    <Input placeholder="Enter the company name" />
                </Form.Item>

                <Form.Item
                    name="employeeCount"
                    label="Employee Count"
                    rules={[{ required: true, message: "" }]}
                >
                    <Select placeholder="Select employee count">
                        <Option value="Personal">Personal</Option>
                        <Option value="1-10">1-10</Option>
                        <Option value="11-50">11-50</Option>
                        <Option value="51-200">51-200</Option>
                        <Option value="201-500">201-500</Option>
                        <Option value="501-1000">501-1000</Option>
                        <Option value="1001-5000">1001-5000</Option>
                        <Option value="5000-10000">5000-10000</Option>
                        <Option value="10001+">10001+</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                        { required: true, message: "" },
                    ]}
                >
                    <Input placeholder="Eg: Cloud computing" />
                </Form.Item>

                <Form.Item className="text-end">
                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};