// axiosInstance.js
import axios from 'axios';
import config from './confiq';

const axiosInstance = axios.create({
    baseURL: config.apiUrl,
    headers: {
        ...config.headers,
    },
});

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.error("API call error:", error);
        return Promise.reject(error);
    }
);

export default axiosInstance;