import {
    Spin,
    Table
} from 'antd';
import {
    ChevronLeftOutline,
    ChevronDownOutline,
    ChevronUpOutline,
    OfficeBuildingOutline,
} from 'heroicons-react';
import React, {
    useEffect,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import { getRegisteredPartner } from '../apiService';
import SearchEmpty from '../../../components/searchEmpty';
import "../index.scss";
import SearchInput from '../../../components/SearchInput';
import moment from 'moment';

function RegisteredPartner() {
    const [registerLead, setRegisterLead] = useState([]);
    const [registerLeadTotal, setRegisterLeadTotal] = useState();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const { vendorName } = location.state || {};

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                setLoading(true);
                const leadQualResponse = await getRegisteredPartner(id, searchQuery);
                setRegisterLead(leadQualResponse?.data || []);
                setRegisterLeadTotal(leadQualResponse?.pagination?.totalCount)
            } catch (error) {
                console.error("Error fetching filter options:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFilters();
    }, [id, searchQuery]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'company',
            key: 'company',
            width: '25%',
            render: (text) => (
                <div className='flex gap-2 items-center'>
                    <OfficeBuildingOutline height={20} /> {text}
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            width: '25%',
            render: (text) => (
                <div className='flex gap-2 items-center'>
                    <OfficeBuildingOutline height={20} className='opacity-0' /> {text}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '25%',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
            width: '25%',
        },
        {
            title: 'Last Activity',
            dataIndex: 'last_active_on',
            key: 'last_active_on',
            width: '25%',
            render: (date) => date && moment(date).isValid() ? moment(date).format("DD MMM YYYY") : '',
        },
        {
            title: 'Expand',
            dataIndex: 'expand',
            key: 'expand',
            width: '25%',
        },
    ];

    const data = registerLead?.map((company, index) => ({
        key: index,
        company: company?.company,
        email: '',
        phone: '',
        last_active_on: '',
        children: company?.users && company?.users?.map((user, userIndex) => {
            return {
                key: `${index}-${userIndex}`,
                full_name: user?.first_name + " " + user?.last_name,
                company: user?.company || 'N/A',
                email: user?.email || 'N/A',
                phone: user?.phone || 'N/A',
                last_active_on: user?.last_active_on || 'N/A',
            };
        }),
    }));

    const handleRowClick = (record, e) => {
        if (e.target.closest('.expand-collapse-icon')) return;
        localStorage.setItem('companyName', record.company);
        navigate(`/partnerships/vendorDetail/${id}`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <div className='flex items-center justify-between gap-2 mb-5'>
                <div className='flex flex-row gap-2'>
                    <div className='relative top-[5px] cursor-pointer' onClick={() => navigate(-1)}>
                        <ChevronLeftOutline />
                    </div>
                    <div className={`text-lg md:text-xl font-bold capitalize`}>
                        Registered Partners with {vendorName}
                    </div>
                </div>
            </div>
            <SearchInput
                placeholder="Search by partner or company name"
                onChange={handleSearchChange}
                className="mb-4"
            />
            {loading ? (
                <Spin className='loader' tip='Loading...' />
            ) : (
                <>
                    <Table
                        columns={columns?.filter(column => column?.key !== 'full_name' && column?.key !== 'expand')}
                        loading={loading}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Table
                                    // columns={columns}
                                    columns={columns?.filter(column => column?.key !== 'company')}
                                    dataSource={record?.children}
                                    pagination={false}
                                    rowClassName={() => 'child-row'}
                                />
                            ),
                            rowExpandable: (record) => !!record?.children,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <div
                                        onClick={(e) => onExpand(record, e)}
                                        className="expand-collapse-icon cursor-pointer flex gap-1 items-center Grayish"
                                    >
                                        Collapse <ChevronUpOutline width={18} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={(e) => onExpand(record, e)}
                                        className="expand-collapse-icon cursor-pointer flex gap-1 items-center Grayish"
                                    >
                                        Expand<ChevronDownOutline width={18} />
                                    </div>
                                ),
                            expandIconColumnIndex: columns?.length,
                        }}
                        dataSource={data}
                        pagination={false}
                        locale={{ emptyText: <SearchEmpty filteredProduct={registerLeadTotal} /> }}
                        onRow={(record) => ({
                            onClick: (e) => handleRowClick(record, e),
                        })}
                        className='expandatable-row'
                    />
                </>
            )}
        </>
    );
}

export default RegisteredPartner;