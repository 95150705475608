import { message } from 'antd';
import {
    DuplicateOutline,
    MailOpenOutline,
    UserOutline
} from 'heroicons-react';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import SearchEmpty from '../searchEmpty';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserRole } from '../../context/userContext';
import { postLastActivity } from '../../pages/partnerships/apiService';

function PartnershipCard({ partnerships, filteredProduct }) {
    const navigate = useNavigate();
    const userRole = useUserRole();
    const { id } = useParams();

    const handleCopyPromoCode = () => {
        message.success(`Copied to clipboard.`);
    };

    const handleCardClick = async (id2, vendorName) => {
        navigate(
            userRole === "Admin"
                ? `/partnerships/registeredPartner/${id2}`
                : `/partnerships/vendorDetail/${id2}`,
            { state: { vendorName } }
        );

        if (userRole === "Partner") {
            await postLastActivity(id);
        }
    };

    return (
        <>
            {partnerships && partnerships.length > 0 ? (
                partnerships.map((partnership, index) => {
                    const isMappedClass = partnership.isMapped ? 'clickable-card' : 'non-clickable-card';
                    return (
                        <div
                            key={index}
                            className={`mb-5 dealsCard dealcard${index % 4} partner-card cursor-pointer ${isMappedClass}`}
                            onClick={() => {
                                if (partnership.isMapped) {
                                    handleCardClick(partnership.id, partnership?.attributes?.vendor_Name);
                                }
                            }}
                        >
                            <div className='flex flex-row gap-4'>
                                <div className='w-20 flex justify-start'>
                                    <div className='vendor-partner-img'>
                                        <img
                                            src={partnership?.attributes?.vendor_Logo?.data[0]?.attributes?.url}
                                            alt=''
                                            className='h-full w-full '
                                        />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='mb-3 text-xl font-bold'>
                                        {partnership?.attributes?.vendor_Name}
                                    </div>
                                    <div className='mb-3 text-base'>
                                        {partnership?.attributes?.summary}
                                    </div>
                                    <div className='flex items-center justify-between'>
                                        <div className='flex gap-1 items-center text-sm	'>
                                            <UserOutline width={18} className='Grayish' /> {partnership?.attributes?.primary_contact || "N/A"}
                                        </div>
                                        {partnership?.isMapped &&
                                            <div className='text-sm	flex gap-1 items-center ' >
                                                <CopyToClipboard
                                                    text={partnership?.attributes?.primary_contact_email}
                                                    onCopy={() =>
                                                        handleCopyPromoCode(partnership?.attributes?.primary_contact_email)
                                                    }
                                                >
                                                    <span
                                                        className=" flex flex-wrap gap-1 items-center"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <MailOpenOutline width={18} className='Grayish' />
                                                        {partnership?.attributes?.primary_contact_email || "N/A"}
                                                        <DuplicateOutline
                                                            width={16}
                                                            className="text-[#5D5FF3]"
                                                        />
                                                    </span>
                                                </CopyToClipboard>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <SearchEmpty filteredProduct={filteredProduct} />
            )}

        </>
    );
}

export default PartnershipCard;